import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ApplicantsInfo,
  ApplicantDetailsRequest,
  MySearch,
  FilterDisplay,
  FilterDisplaySelenctionFlowInfo,
  FilterDisplayProgressInfo,
  FilterDisplayDecisionInfo,
  FilterTagInfo,
  FilterCondition,
  Option,
  SortCondition,
  ApplicantsListInfo,
  SearchResult,
  UpdateJudgemtnResult,
  ScreenTransitionInput,
  initScreenTransitionInput,
  initeSearchRequest,
  initMySearch,
  initUpdateJudgmentRequest,
  initFilterDisplay,
  initFilterCondition,
  initSortCondition,
  initSearchResult,
  initApplicantDetailsRequest,
  initMCAXS020UpdateExcludeRequest,
  initMCAXS020UpdateUnExcludeRequest,
  initOption,
  Data,
  FlowMasterInfo,
  SCREEN_ID,
  SelectedSearch,
  initSelectedSearch,
  MaxCardInfoResult,
  MaxCardInfo,
  FilterRequest,
  LIST_DISPLAY_TYPE_MAX_CARD,
  LIST_DISPLAY_TYPE_SIMPLE_LIST,
  SelectionInfo,
  selectionInfo,
} from 'pages/MCAXS020/formConfig'
import { PagerMaxCard } from 'pages/MCAXS020/apiConfig'
import { MCAXS020SearchRequest } from 'types/MCAXS020/MCAXS020SearchRequest'
import { MCAXS020UpdateJudgmentRequest } from 'types/MCAXS020/MCAXS020UpdateJudgmentRequest'
import { MCAXS020UpdateJudgmentNextStepRequest } from 'types/MCAXS020/MCAXS020UpdateJudgmentNextStepRequest'
import { MCAXS020UpdateJudgmentRejectionNoticeRequest } from 'types/MCAXS020/MCAXS020UpdateJudgmentRejectionNoticeRequest'
import { MCAXS020GetMySearchRequest } from 'types/MCAXS020/MCAXS020GetMySearchRequest'
import { MCAXS020UpdateExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateExcludeRequest'
import { MCAXS020UpdateUnExcludeRequest } from 'types/MCAXS020/MCAXS020UpdateUnExcludeRequest'
import { MCAXS020ServiceModeUpdateRequest } from 'types/MCAXS020/MCAXS020ServiceModeUpdateRequest'
import { MCAXS020CheckSelectionFlowandStepRequest } from 'types/MCAXS020/MCAXS020CheckSelectionFlowandStepRequest'
import { stringToDateTime } from 'common/generalUtil'
import {
  SearchCondition,
  initSearchCondition,
} from 'pages/MCAXS020/searchConditionConfig'
import { deepEqual } from 'utils/misc'
import {
  SelectionFlowInitData,
  Status,
  DecisionProcessChangeQueryResult,
} from './selectionStatusUpdateReducer'
import { magiContants } from 'utils/contants'
import { initialSelectionFlowInitData } from 'pages/MCAYS030/formConfig'
import { initialDecisionProcessChangeQueryResult } from 'pages/MCAYS020/formConfig'

const initialState: {
  screenTransitionInput: ScreenTransitionInput //一覧画面遷移時の入力情報
  searchRequest: MCAXS020SearchRequest //検索リクエスト
  mySearch: MySearch //My検索設定情報
  updateJudgmentRequest: MCAXS020UpdateJudgmentRequest
  filterDisplay: FilterDisplay //フィルター表示情報
  filterCondition: FilterCondition //フィルター条件
  sortCondition: SortCondition //ソート条件
  page: number // 表示ページ番号
  searchResult: SearchResult // 検索結果
  data: Data[] //一覧表示用に整形した検索結果
  preData: Data[] //ソート・フィルター時に最大カード検索の為先行して保持しておく選考管理IDとSysVerNum1
  applicantsInfo: ApplicantsInfo[] //他画面遷移時情報
  applicantDetailsRequest: ApplicantDetailsRequest //詳細画面遷移時情報
  updateExcludeRequest: MCAXS020UpdateExcludeRequest //対象外にするリクエスト
  updateUnExcludeRequest: MCAXS020UpdateUnExcludeRequest //対象外から戻すリクエスト
  searchCondition: SearchCondition
  selectedSearch: SelectedSearch
  selectedAccounts: string[] //チェックボックス選択状態
  refreshFlag: number
  loadingFlag: boolean
  hasNewData: boolean
  searchCount: number
  totalCount: number
  needUpdate: boolean
  selectionFlowInitData2: SelectionFlowInitData
  specificTargetFirstDisplayInfo: Data[]
  circleLoadingFlag: boolean
  displaySearchCriteriaTitleOpenFlag: boolean
  selectionProcessResultDialogFlag: boolean
  targetFlowflag: number
  listDisplayType: string //一覧表示形式
  maxCardAddictionalInfo: MaxCardInfo[] //最大カード形式情報
  inPageData: PagerMaxCard[] //ソート後フィルター後のエントリーを保持
  info: SelectionInfo
  DecisionProcessChangeQueryResult: DecisionProcessChangeQueryResult
  targetFlowMasterInfo: FlowMasterInfo[]
  targetFlowMasterList: FlowMasterInfo[]
} = {
  screenTransitionInput: initScreenTransitionInput, //一覧画面遷移時の入力情報
  searchRequest: initeSearchRequest, //検索リクエスト
  mySearch: initMySearch, //My検索設定情報
  updateJudgmentRequest: initUpdateJudgmentRequest,
  filterDisplay: initFilterDisplay, //フィルター表示情報
  filterCondition: initFilterCondition, //フィルター条件
  sortCondition: initSortCondition, //ソート条件
  page: 0, // 表示ページ番号
  searchResult: initSearchResult, // 検索結果
  data: [], //一覧表示用に整形した検索結果
  preData: [],
  applicantsInfo: [], //他画面遷移時情報
  applicantDetailsRequest: initApplicantDetailsRequest, //詳細画面遷移時情報
  updateExcludeRequest: initMCAXS020UpdateExcludeRequest, //対象外にするリクエスト
  updateUnExcludeRequest: initMCAXS020UpdateUnExcludeRequest, //対象外から戻すリクエスト
  searchCondition: initSearchCondition,
  selectedSearch: initSelectedSearch,
  selectedAccounts: [],
  refreshFlag: 0,
  loadingFlag: false,
  hasNewData: false,
  searchCount: 1,
  totalCount: 0,
  needUpdate: false,
  selectionFlowInitData2: initialSelectionFlowInitData,
  specificTargetFirstDisplayInfo: [],
  circleLoadingFlag: false,
  displaySearchCriteriaTitleOpenFlag: false,
  selectionProcessResultDialogFlag: false,
  targetFlowflag: 0,
  listDisplayType: '', //一覧表示形式
  maxCardAddictionalInfo: [],
  inPageData: [],
  info: selectionInfo,
  DecisionProcessChangeQueryResult: initialDecisionProcessChangeQueryResult,
  targetFlowMasterInfo: [],
  targetFlowMasterList: [],
}

interface ProgressInfo {
  progressName: string //選考名
  decisionDivisionType: string // 判定区分種別
}

const entryListSlice = createSlice({
  name: 'entryList',
  initialState,
  reducers: {
    search(
      state,
      action: PayloadAction<{
        request: MCAXS020SearchRequest
        serviceModeUpdateRequest?: MCAXS020ServiceModeUpdateRequest
        isInitFilter: boolean
        mySearch?: MySearch
        onSearch?: () => void
      }>
    ) {
      state.searchRequest = action.payload.request
      return state
    },
    searchSelectionFlow(
      state,
      action: PayloadAction<{
        targetFlowMasterInfo: FlowMasterInfo[]
        targetFlowMasterList: FlowMasterInfo[]
      }>
    ) {
      state.targetFlowMasterInfo = action.payload.targetFlowMasterInfo
      state.targetFlowMasterList = action.payload.targetFlowMasterList
      return state
    },
    setSelectionFlowInitData(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResult>
    ) {
      state.DecisionProcessChangeQueryResult = action.payload
      return state
    },
    setSelectionProcessResultDialogFlag(state, action: PayloadAction<boolean>) {
      state.selectionProcessResultDialogFlag = action.payload
      return state
    },
    setTargetFlowflag(state, action: PayloadAction<number>) {
      state.targetFlowflag = action.payload
      return state
    },
    setSearchResult(state, action: PayloadAction<SearchResult>) {
      state.searchResult = action.payload
      return state
    },
    resetData(state) {
      state.data = []
      return state
    },
    setInitDisplay(
      state,
      action: PayloadAction<{
        isInitFilter: boolean
        searchResult: SearchResult
      }>
    ) {
      const searchResult = action.payload.searchResult

      //フィルター欄更新
      if (!action.payload.isInitFilter) {
        //フィルタ欄の選考ステップ数を更新
        const selectSelectionFlowSettingId =
          state.filterCondition.selectionFlowSelect.value
        if (selectSelectionFlowSettingId !== '') {
          const applicantsListInfo = searchResult.applicantsListInfo
          const flowMasterInfoArray = searchResult.flowMasterInfo.filter(
            info => info.selectionFlowSettingId === selectSelectionFlowSettingId
          )
          const newFilterDisplay: FilterDisplay = {
            ...state.filterDisplay,
            filterDisplayProgressInfoAllCount: applicantsListInfo.filter(
              info =>
                info.selectionFlowSettingId === selectSelectionFlowSettingId
            ).length,
            filterDisplayProgressInfo: createFilterDisplayProgressInfoArray(
              selectSelectionFlowSettingId,
              state.screenTransitionInput.listId,
              JSON.parse(state.searchRequest.searchCondition),
              flowMasterInfoArray,
              applicantsListInfo
            ),
          }
          state.filterDisplay = newFilterDisplay
        }
      }

      const specificConditionSelectionManagementIdList =
        state.screenTransitionInput.specificSelectionManagementIdList
      const filterCondition = state.filterCondition
      const sortCondition = state.sortCondition

      const filteredDisplayInfo = filterEntryListDiplayInfo(
        searchResult.displayInfo,
        filterCondition,
        searchResult,
        specificConditionSelectionManagementIdList
      )

      const newDisplayInfo = sortDiplayInfo(filteredDisplayInfo, sortCondition)

      if (state.searchRequest.sourceFunctionId === 'MCARS010') {
        state.searchCondition =
          searchResult.searchCondition !== null
            ? JSON.parse(searchResult.searchCondition)
            : null

        state.mySearch = {
          entrySearchCriteriaSettingId:
            state.screenTransitionInput.entrySearchCriteriaSettingId,
          searchCriteriaName:
            searchResult.searchCriteriaName !== null
              ? searchResult.searchCriteriaName
              : '',
          searchCriteria:
            searchResult.searchCondition !== null
              ? searchResult.searchCondition
              : '',
          searchCriteriaDisplay: searchResult.searchConditionDisplay,
        }
      }
      state.data = newDisplayInfo
      state.page = 0
      state.selectedAccounts = []
      state.listDisplayType = action.payload.searchResult.listDisplayType

      if (
        deepEqual(initFilterCondition, state.filterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length ===
        0
      ) {
        state.searchResult.totalCount = state.totalCount
      } else {
        state.searchResult.totalCount = newDisplayInfo.length
      }

      return state
    },
    updateSearchRequest(state, action: PayloadAction<MCAXS020SearchRequest>) {
      state.searchRequest = action.payload
      return state
    },
    revertFilterCondition(state) {
      const nameForm = document.getElementById('nameForm')
      const { filterCondition } = state
      if (nameForm != null)
        (nameForm as HTMLInputElement).value = filterCondition.nameForm

      const specificCondition = document.getElementById('specificCondition')
      if (specificCondition != null)
        (specificCondition as HTMLInputElement).checked = true
      return state
    },
    preUpdateFilterCondition(
      state,
      action: PayloadAction<{
        pram: FilterRequest
      }>
    ) {
      let displayInfo = action.payload.pram.searchResult.displayInfo
      const searchResult = action.payload.pram.searchResult
      let newDiplayInfo: Data[]
      if (
        state.screenTransitionInput.specificSelectionManagementIdList.length > 0
      ) {
        displayInfo = state.specificTargetFirstDisplayInfo
      }
      if (state.hasNewData) {
        const { filterCondition } = state
        const filteredDisplayInfo = filterEntryListDiplayInfo(
          displayInfo,
          filterCondition,
          searchResult,
          state.screenTransitionInput.specificSelectionManagementIdList
        )
        newDiplayInfo = sortDiplayInfo(filteredDisplayInfo, state.sortCondition)
      }
      const filterCondition = action.payload.pram.filterCondition
      const filteredDisplayInfo = filterEntryListDiplayInfo(
        displayInfo,
        filterCondition,
        searchResult,
        state.screenTransitionInput.specificSelectionManagementIdList
      )
      newDiplayInfo = sortDiplayInfo(filteredDisplayInfo, state.sortCondition)

      state.preData = newDiplayInfo
      state.inPageData = createInPageData(state.listDisplayType, newDiplayInfo)

      return
    },
    updateFilterCondition(
      state,
      action: PayloadAction<{
        filterCondition: FilterCondition
        searchResult: SearchResult
      }>
    ) {
      let displayInfo = action.payload.searchResult.displayInfo
      const searchResult = action.payload.searchResult
      if (state.hasNewData) {
        state.data = state.preData
        state.hasNewData = false
      }
      if (searchResult.displayInfo.length < state.totalCount) {
        state.needUpdate = true
        state.loadingFlag = true
      }

      const filterCondition = action.payload.filterCondition

      if (
        deepEqual(filterCondition, initFilterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length ===
        0
      ) {
        let cantReferenceInfoCount = 0
        displayInfo.forEach(info => {
          if (info.specificTargetDataGetFlag !== '0') {
            cantReferenceInfoCount++
          }
        })
        state.searchResult.totalCount =
          state.totalCount - cantReferenceInfoCount
      } else {
        state.searchResult.totalCount = state.preData.length
      }
      state.data = state.preData
      state.filterCondition = filterCondition
      state.page = 0
      state.selectedAccounts = []

      return state
    },
    updateSelectionSelect(state, action: PayloadAction<Option>) {
      const selectSelectionFlowSettingId = action.payload.value

      const applicantsListInfo = state.searchResult.applicantsListInfo

      const flowMasterInfoArray = state.searchResult.flowMasterInfo.filter(
        info => info.selectionFlowSettingId === selectSelectionFlowSettingId
      )

      state.filterDisplay.filterDisplayProgressInfoAllCount = applicantsListInfo.filter(
        info => info.selectionFlowSettingId === selectSelectionFlowSettingId
      ).length
      state.filterDisplay.filterDisplayProgressInfo = createFilterDisplayProgressInfoArray(
        selectSelectionFlowSettingId,
        state.screenTransitionInput.listId,
        JSON.parse(state.searchRequest.searchCondition),
        flowMasterInfoArray,
        applicantsListInfo
      )
      state.filterCondition.selectionFlowSelect = action.payload

      return state
    },
    updateProgressFilter(state, action: PayloadAction<Option>) {
      const progressStatusSelect = action.payload.value
      const selectionFlowSelect =
        state.filterCondition.selectionFlowSelect.value

      const flowMasterInfoArray = state.searchResult.flowMasterInfo

      const flowMasterInfo = flowMasterInfoArray.find(
        info =>
          info.selectionFlowSettingId === selectionFlowSelect &&
          info.progressStatusSettingId === progressStatusSelect
      )
      if (flowMasterInfo === undefined) {
        return state
      }

      let filterDisplayDecisionInfoArray: FilterDisplayDecisionInfo[] = []

      const decisionDivisionType = flowMasterInfo.decisionDivisionType

      switch (decisionDivisionType) {
        case '0':
        case '2':
          //[0]合否（面接）,[2]合否（書類選考）
          filterDisplayDecisionInfoArray = [middle, pass, fail]
          break
        case '1':
          //[1]参加不参加
          filterDisplayDecisionInfoArray = [participate, notParticipate, other]
          break
        default:
          filterDisplayDecisionInfoArray = []
          break
      }

      state.filterDisplay.filterDisplayDecisionInfo = filterDisplayDecisionInfoArray

      return state
    },
    updateTagFilter(state, action: PayloadAction<Option[]>) {
      //タグを指定するモーダル側でこのreducerが呼ばれる想定
      const tagMasterInfoArray = state.searchResult.tagMasterInfo

      const newFilterDisplayTagInfo: FilterTagInfo[] = []

      const tagSettingIds: string[] = action.payload.map(tag => tag.value)

      tagSettingIds.forEach(tagSettingId => {
        const tagMasterInfo = tagMasterInfoArray.find(
          info => info.tagSettingId === tagSettingId
        )
        if (tagMasterInfo !== undefined) {
          const tag: FilterTagInfo = {
            tagSettingId: tagMasterInfo.tagSettingId,
            tagName: tagMasterInfo.tagName,
          }
          newFilterDisplayTagInfo.push(tag)
        }
      })

      state.filterDisplay.filterDisplayTagInfo = newFilterDisplayTagInfo
      return state
    },
    preUpdateSortCondition(
      state,
      action: PayloadAction<{
        order: string
        searchResult: SearchResult
      }>
    ) {
      const searchResult = action.payload.searchResult
      const order = action.payload.order
      const filterCondition = state.filterCondition
      const sortCondition = { ...state.sortCondition }
      sortCondition.order = order

      const filteredDiplayInfo = filterEntryListDiplayInfo(
        searchResult.displayInfo,
        filterCondition,
        searchResult,
        state.screenTransitionInput.specificSelectionManagementIdList
      )
      const newDiplayInfo = sortDiplayInfo(filteredDiplayInfo, sortCondition)

      state.preData = newDiplayInfo
      state.inPageData = createInPageData(state.listDisplayType, newDiplayInfo)
      return
    },
    updateSortCondition(
      state,
      action: PayloadAction<{
        order: string
        searchResult: SearchResult
      }>
    ) {
      const { totalCount } = state
      const searchResult = action.payload.searchResult

      if (searchResult.displayInfo.length < totalCount) {
        state.needUpdate = true
      }

      state.data = state.preData
      state.sortCondition.order = action.payload.order
      state.page = 0
      state.selectedAccounts = []
      return state
    },
    updateListDisplayTypeRequest(
      state,
      action: PayloadAction<{
        listDisplayType: string
        selectionManagementInfo: PagerMaxCard[]
      }>
    ) {
      return state
    },
    updateListDisplayType(
      state,
      action: PayloadAction<{
        listDisplayType: string
      }>
    ) {
      const listDisplayType = action.payload.listDisplayType
      state.listDisplayType = listDisplayType
      return state
    },
    updateMaxCardInfo(
      //選考管理IDリストから一致するものを取り出して更新
      state,
      action: PayloadAction<MaxCardInfoResult>
    ) {
      const maxCardObj = action.payload.maxCardObj
      const data = state.data
      maxCardObj != null &&
        maxCardObj.forEach(maxCard => {
          const targetData = data.find(
            i => i.selectionManagementId === maxCard.selectionManagementId
          )
          //アイテムの数だけ繰り返す
          /**対象データが存在すればstate更新 */
          if (targetData) {
            targetData.managementItem = maxCard.managementItem
            targetData.comment = maxCard.comment
            targetData.messageList = maxCard.messageList
          }
        })
      state.data = data
      return state
    },
    updatePage(
      state,
      action: PayloadAction<{
        page: number
        searchResult: SearchResult
      }>
    ) {
      const page = action.payload.page
      const searchResult = action.payload.searchResult
      state.page = page

      if (state.hasNewData) {
        const { filterCondition } = state
        const filteredDisplayInfo = filterEntryListDiplayInfo(
          searchResult.displayInfo,
          filterCondition,
          searchResult,
          state.screenTransitionInput.specificSelectionManagementIdList
        )

        state.data = sortDiplayInfo(filteredDisplayInfo, state.sortCondition)
        state.hasNewData = false
      }

      /**
       * Pagination out of range before the second request returns
       */
      const rowsPerPage = 100
      const startIndex = page * rowsPerPage
      const endIndex =
        startIndex + rowsPerPage > state.data.length
          ? state.data.length
          : startIndex + rowsPerPage

      if (state.data.slice(startIndex, endIndex).length === 0) {
        state.needUpdate = true
      }

      return state
    },
    /**最大カード用ページ更新*/
    updatePageMaxCard(
      state,
      action: PayloadAction<{
        selectionManagementInfo: PagerMaxCard[]
        page: number
        searchResult: SearchResult | null
      }>
    ) {
      return state
    },
    updatePageCheck(
      state,
      action: PayloadAction<{
        page: number
        searchResult: SearchResult
      }>
    ) {
      return
    },
    preUpdateOnSelectAll(
      state,
      action: PayloadAction<{
        searchResult: SearchResult
        revertOnSelectAll: React.Dispatch<React.SetStateAction<boolean>>
      }>
    ) {
      let newDiplayInfo: Data[]
      if (state.hasNewData) {
        const searchResult = action.payload.searchResult
        const { filterCondition } = state
        const filteredDisplayInfo = filterEntryListDiplayInfo(
          searchResult.displayInfo,
          filterCondition,
          searchResult,
          state.screenTransitionInput.specificSelectionManagementIdList
        )
        newDiplayInfo = sortDiplayInfo(filteredDisplayInfo, state.sortCondition)
        state.preData = newDiplayInfo
        state.inPageData = createInPageData(
          state.listDisplayType,
          newDiplayInfo
        )
      }
      return state
    },
    updateOnSelectAll(state, action: PayloadAction<SearchResult>) {
      const searchResult = action.payload
      if (state.hasNewData) {
        state.data = state.preData
        state.hasNewData = false
      }

      if (searchResult.displayInfo.length < state.totalCount) {
        state.needUpdate = true
      }

      return state
    },
    updateOnNewData(state, action: PayloadAction<SearchResult>) {
      const { filterCondition } = state
      const searchResult = action.payload
      const filteredDisplayInfo = filterEntryListDiplayInfo(
        searchResult.displayInfo,
        filterCondition,
        searchResult,
        state.screenTransitionInput.specificSelectionManagementIdList
      )

      state.data = sortDiplayInfo(filteredDisplayInfo, state.sortCondition)
      state.selectedAccounts = []
      state.hasNewData = false
      state.needUpdate = false

      if (
        deepEqual(initFilterCondition, state.filterCondition) &&
        state.screenTransitionInput.specificSelectionManagementIdList.length ===
        0
      ) {
        let cantReferenceInfoCount = 0
        searchResult.displayInfo.forEach(info => {
          if (info.specificTargetDataGetFlag !== '0') {
            cantReferenceInfoCount++
          }
        })
        state.searchResult.totalCount =
          state.totalCount - cantReferenceInfoCount
      } else {
        state.searchResult.totalCount = state.data.length
      }

      return state
    },
    updateDecision(
      state,
      action: PayloadAction<MCAXS020UpdateJudgmentRequest>
    ) {
      state.updateJudgmentRequest = action.payload
      return state
    },
    updateDecisionNextStep(
      state,
      action: PayloadAction<MCAXS020UpdateJudgmentNextStepRequest>
    ) {
      state.updateJudgmentRequest = action.payload
      return state
    },
    updateDecisionTooltipHidden(
      state,
      action: PayloadAction<MCAXS020UpdateJudgmentNextStepRequest>
    ) {
      state.updateJudgmentRequest = action.payload
      return state
    },
    updateDecisionRejectionNotice(
      state,
      action: PayloadAction<MCAXS020UpdateJudgmentRejectionNoticeRequest>
    ) {
      state.updateJudgmentRequest = action.payload
      return state
    },
    setApplicantsInfo(state, action: PayloadAction<ApplicantsInfo[]>) {
      state.applicantsInfo = action.payload
      return state
    },
    setApplicantDetailsRequest(
      state,
      action: PayloadAction<ApplicantDetailsRequest>
    ) {
      state.applicantDetailsRequest = action.payload
      return state
    },
    updateJudgementResult(state, action: PayloadAction<UpdateJudgemtnResult>) {
      const applicantsListInfo = state.searchResult.applicantsListInfo

      const result = action.payload
      applicantsListInfo.forEach(info => {
        if (info.selectionManagementId === result.selectionManagementId) {
          info.progressStatusSettingId = result.progressStatusSettingId
          info.decisionDivision = result.decisionDivision
          info.sysVersionNumber1 = result.sysVersionNumber1
          info.sysVersionNumber2 = result.sysVersionNumber2
        }
      })

      state.searchResult.applicantsListInfo = applicantsListInfo
      return state
    },
    updateMySearch(state, action: PayloadAction<MySearch>) {
      state.mySearch = action.payload
      return state
    },
    updateScreenTransitionInput(
      state,
      action: PayloadAction<ScreenTransitionInput>
    ) {
      state.screenTransitionInput = action.payload
      return state
    },
    getMySearch(
      state,
      action: PayloadAction<{
        mySearchRequest: MCAXS020GetMySearchRequest
        searchRequest: MCAXS020SearchRequest
      }>
    ) {
      state.searchRequest = action.payload.searchRequest
      const newScreenTransitionInput = state.screenTransitionInput
      newScreenTransitionInput.screenId =
        action.payload.searchRequest.sourceFunctionId
      state.screenTransitionInput = newScreenTransitionInput

      return state
    },
    setMySearchResult(state, action: PayloadAction<MySearch>) {
      state.mySearch = action.payload
      return state
    },
    updateExclude(
      state,
      action: PayloadAction<{
        excludeRequest: MCAXS020UpdateExcludeRequest
        searchRequest: MCAXS020SearchRequest
      }>
    ) {
      state.updateExcludeRequest = action.payload.excludeRequest
      state.searchRequest = action.payload.searchRequest

      const newScreenTransitionInput = state.screenTransitionInput
      newScreenTransitionInput.screenId =
        action.payload.searchRequest.sourceFunctionId
      state.screenTransitionInput = newScreenTransitionInput

      return state
    },
    updateUnExclude(
      state,
      action: PayloadAction<{
        unExcludeRequest: MCAXS020UpdateUnExcludeRequest
        searchRequest: MCAXS020SearchRequest
      }>
    ) {
      state.updateUnExcludeRequest = action.payload.unExcludeRequest
      state.searchRequest = action.payload.searchRequest

      const newScreenTransitionInput = state.screenTransitionInput
      newScreenTransitionInput.screenId =
        action.payload.searchRequest.sourceFunctionId
      state.screenTransitionInput = newScreenTransitionInput

      return state
    },
    setJudgementStatusList(
      state,
      action: PayloadAction<{
        selectionManagementId: string
        judgementStatus: string
        show: boolean
      }>
    ) {
      const data = state.data
      const targetData = data.find(
        i => i.selectionManagementId === action.payload.selectionManagementId
      )

      if (targetData) {
        targetData.decisionChangeToolTipOpen = action.payload.show
        targetData.decisionDivision = action.payload.judgementStatus
      }

      state.data = data

      const displayInfo = state.searchResult.displayInfo
      const searchResultTargetData = state.searchResult.displayInfo.find(
        i => i.selectionManagementId === action.payload.selectionManagementId
      )

      if (searchResultTargetData) {
        searchResultTargetData.decisionDivision = action.payload.judgementStatus
      }

      state.searchResult.displayInfo = displayInfo

      const targetSpecificTargetFirstDisplayInfo = state.specificTargetFirstDisplayInfo.find(
        i => i.selectionManagementId === action.payload.selectionManagementId
      )

      if (targetSpecificTargetFirstDisplayInfo) {
        targetSpecificTargetFirstDisplayInfo.decisionDivision =
          action.payload.judgementStatus
      }

      return state
    },
    setSearchCondition(state, action: PayloadAction<SearchCondition>) {
      state.searchCondition = action.payload

      const newSearchRequest = state.searchRequest
      newSearchRequest.searchCondition = JSON.stringify(action.payload)
      state.searchRequest = newSearchRequest

      return state
    },
    setSelectedSearch(state, action: PayloadAction<SelectedSearch>) {
      state.selectedSearch = action.payload
      return state
    },
    setSelectedAccounts(state, action: PayloadAction<string[]>) {
      state.selectedAccounts = action.payload
      return state
    },
    setMCAXS020RefreshFlag(state) {
      state.refreshFlag = state.refreshFlag === 0 ? 1 : 0
    },
    setLoadingFlag(state, action: PayloadAction<boolean>) {
      state.loadingFlag = action.payload
      return state
    },
    setHasNewData(state, action: PayloadAction<boolean>) {
      state.hasNewData = action.payload
      return state
    },
    setSearchCount(state, action: PayloadAction<number>) {
      state.searchCount = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload
    },
    updateClickData(state, action: PayloadAction<string>) {
      const data = state.data
      const targetData = data.find(
        i => i.selectionManagementId === action.payload
      )
      if (targetData) {
        targetData.unread = false
      }

      const targetDisplayInfo = state.searchResult.displayInfo.find(
        i => i.selectionManagementId === action.payload
      )

      if (targetDisplayInfo) {
        targetDisplayInfo.unread = false
      }

      const targetSpecificTargetFirstDisplayInfo = state.specificTargetFirstDisplayInfo.find(
        i => i.selectionManagementId === action.payload
      )

      if (targetSpecificTargetFirstDisplayInfo) {
        targetSpecificTargetFirstDisplayInfo.unread = false
      }

      return state
    },
    setNeedUpdate(state, action: PayloadAction<boolean>) {
      state.needUpdate = action.payload
    },
    updateMessageClickData(state, action: PayloadAction<string>) {
      const data = state.data
      const targetData = data.find(i => i.message.id === action.payload)
      if (targetData) {
        targetData.message.messageRead = 'read'
      }
      const targetDataMaxCard = data.find(i => {
        if (i.messageList != null) {
          return i.messageList.find(j => j.id === action.payload)
        } else {
          return false
        }
      })
      if (targetDataMaxCard) {
        const targetMessage = targetDataMaxCard.messageList.find(
          i => i.id === action.payload
        )
        if (targetMessage) {
          targetMessage.messageRead = 'read'
        }
      }
      state.data = data
      return state
    },
    updateFilterDisplay(state, action: PayloadAction<SearchResult>) {
      //選考フィルター：選考フロー情報作成
      const filterDisplaySelenctionFlowInfo = createFilterDisplaySelenctionFlowInfo(
        action.payload
      )
      //選考フィルター：よく使われるタグ情報作成
      const filterDisplayCommonlyTagInfo = createFilterDisplayCommonlyTagInfo(
        action.payload
      )
      state.filterDisplay = {
        ...state.filterDisplay,
        filterDisplaySelenctionFlowInfo: filterDisplaySelenctionFlowInfo,
        filterDisplayCommonlyTagInfo: filterDisplayCommonlyTagInfo,
      }
      return state
    },
    clearFilter(state, action: PayloadAction<{ serviceMode: string }>) {
      //フィルター欄クリア
      const clearedFilterDisplay = {
        ...state.filterDisplay,
        filterDisplayProgressInfoAllCount: 0,
        filterDisplayProgressInfo: [],
        filterDisplayDecisionInfo: [],
        filterDisplayTagInfo: [],
      }

      state.filterCondition = initFilterCondition
      state.filterDisplay = clearedFilterDisplay

      //フィルターの氏名欄クリア
      const nameForm = document.getElementById('nameForm') as HTMLInputElement
      if (nameForm !== null) {
        nameForm.value = ''
      }

      //ソート順初期化
      if (action.payload.serviceMode !== '1') {
        state.sortCondition.order = '0'
      } else {
        state.sortCondition.order = '4'
      }

      return state
    },
    filterSelectionFlowInfo(state, action: PayloadAction<Data>) {
      const resultObject = action.payload
      const flowMasterInfoArray = state.searchResult.flowMasterInfo.filter(
        item => {
          return (
            item.selectionFlowSettingId === resultObject.selectionFlowSettingId
          )
        }
      )
      const selectProgressInfo = flowMasterInfoArray.filter(item => {
        return (
          item.progressStatusSettingId === resultObject.progressStatusSettingId
        )
      })[0]
      const leftProgresslst: string[] = []
      leftProgresslst.push(magiContants.PROGRESS_TYPE_INTERVIEW)
      leftProgresslst.push(magiContants.PROGRESS_TYPE_CUSTOM)
      leftProgresslst.push(magiContants.PROGRESS_TYPE_INFIX)
      leftProgresslst.push(magiContants.PROGRESS_TYPE_INTERNSHIP)
      leftProgresslst.push(magiContants.PROGRESS_TYPE_CONSENT)
      leftProgresslst.push(magiContants.PROGRESS_TYPE_ENTRY)
      const rightProgresslst: string[] = []
      rightProgresslst.push(magiContants.PROGRESS_TYPE_NOPASS)
      rightProgresslst.push(magiContants.PROGRESS_TYPE_NOADOPTION)
      rightProgresslst.push(magiContants.PROGRESS_TYPE_DISMISS)

      // 左進捗の進捗ステータス
      const leftProgressList = flowMasterInfoArray
        .filter(item => {
          return leftProgresslst.includes(item.progressType)
        })
        .map(mapItem => {
          return {
            id: mapItem.displayOrder,
            name: mapItem.progressName,
            settingId: Number(mapItem.progressStatusSettingId),
          }
        })

      // 右進捗の進捗ステータス
      const rightProgressList: Status[] = flowMasterInfoArray
        .filter(item => {
          return rightProgresslst.includes(item.progressType)
        })
        .map(mapItem => {
          return {
            id: mapItem.displayOrder,
            name: mapItem.progressName,
            settingId: Number(mapItem.progressStatusSettingId),
          }
        })

      let flag = selectProgressInfo.displayOrder // 旗の位置
      let leftSelect // 左進捗の選択位置
      let rightSelect = selectProgressInfo.displayOrder // 右進捗の選択位置
      let leftGrey // 左進捗のグレー表示の標識
      let rightGrey = false // 右進捗のグレー表示の標識
      let target // 対象の標識
      if (
        rightProgresslst.includes(selectProgressInfo.progressType.toString())
      ) {
        leftGrey = true
        leftSelect = -1
      } else {
        leftGrey = false
        leftSelect = selectProgressInfo.displayOrder
      }

      if (resultObject.notApplicable) {
        leftGrey = true
        rightGrey = true
        target = false
        flag = -1
        leftSelect = -1
        rightSelect = -1
      } else {
        target = true
      }

      const selectionFlowInitData: SelectionFlowInitData = {
        flag: flag, // 旗の位置
        leftSelect: leftSelect,
        rightSelect: rightSelect, // 右進捗の選択位置
        leftGrey: leftGrey,
        rightGrey: rightGrey,
        target: target,
        leftProgressList: leftProgressList,
        rightProgressList: rightProgressList,
      }
      state.selectionFlowInitData2 = selectionFlowInitData
      return state
    },
    setSpecificTargetFirstDisplayInfo(state, action: PayloadAction<Data[]>) {
      state.specificTargetFirstDisplayInfo = action.payload
      return state
    },
    setCircleLoadingFlag(state, action: PayloadAction<boolean>) {
      state.circleLoadingFlag = action.payload
      return state
    },
    setDisplaySearchCriteriaTitleOpenFlag(
      state,
      action: PayloadAction<boolean>
    ) {
      state.displaySearchCriteriaTitleOpenFlag = action.payload
      return state
    },
    checkNotAdoptedNotificationPreference(
      state,
      action: PayloadAction<{
        handleChangeStatus: (
          status: string,
          selectionManagemntId: string
        ) => void
        setOpenMessageModal: React.Dispatch<React.SetStateAction<boolean>>
        setModalMessage: React.Dispatch<React.SetStateAction<string>>
        selectionManagementId: string
      }>
    ) {
      return state
    },
    checkSelectionFlowandStep(
      state,
      action: PayloadAction<{
        checkSelection: (
          targetData: Data[],
          info: SelectionInfo,
          sameSelectionFlag: boolean
        ) => void
        selectionManagementIdArray: number[]
        targetData: Data[]
        info: SelectionInfo
      }>
    ) {
      return state
    },
  },
})

export const {
  search,
  searchSelectionFlow,
  setSelectionFlowInitData,
  setSelectionProcessResultDialogFlag,
  setTargetFlowflag,
  setSearchResult,
  resetData,
  setInitDisplay,
  updateSearchRequest,
  updateSelectionSelect,
  updateProgressFilter,
  updateTagFilter,
  revertFilterCondition,
  updateFilterCondition,
  preUpdateFilterCondition,
  updateSortCondition,
  preUpdateSortCondition,
  updateListDisplayType,
  updatePage,
  updatePageMaxCard,
  preUpdateOnSelectAll,
  updateOnSelectAll,
  updateOnNewData,
  updateDecision,
  updateDecisionNextStep,
  updateDecisionTooltipHidden,
  updateDecisionRejectionNotice,
  setApplicantsInfo,
  setApplicantDetailsRequest,
  updateJudgementResult,
  updateMySearch,
  updateScreenTransitionInput,
  getMySearch,
  setMySearchResult,
  updateExclude,
  updateUnExclude,
  setJudgementStatusList,
  setSearchCondition,
  setSelectedSearch,
  setSelectedAccounts,
  setMCAXS020RefreshFlag,
  setLoadingFlag,
  setHasNewData,
  setSearchCount,
  setTotalCount,
  updateClickData,
  setNeedUpdate,
  updateMessageClickData,
  updateFilterDisplay,
  clearFilter,
  filterSelectionFlowInfo,
  setSpecificTargetFirstDisplayInfo,
  setCircleLoadingFlag,
  setDisplaySearchCriteriaTitleOpenFlag,
  updateMaxCardInfo,
  updateListDisplayTypeRequest,
  checkNotAdoptedNotificationPreference,
  checkSelectionFlowandStep,
} = entryListSlice.actions
export default entryListSlice.reducer

const middle: FilterDisplayDecisionInfo = {
  decisionDivision: '1', //判定区分
  decisionName: '判定中', //判定名
}
const participate: FilterDisplayDecisionInfo = {
  decisionDivision: '2', //判定区分
  decisionName: '参加', //判定名
}
const pass: FilterDisplayDecisionInfo = {
  decisionDivision: '3', //判定区分
  decisionName: '合格', //判定名
}
const notParticipate: FilterDisplayDecisionInfo = {
  decisionDivision: '4', //判定区分
  decisionName: '不参加', //判定名
}
const fail: FilterDisplayDecisionInfo = {
  decisionDivision: '5', //判定区分
  decisionName: '不合格', //判定名
}
const other: FilterDisplayDecisionInfo = {
  decisionDivision: '6', //判定区分
  decisionName: 'その他', //判定名
}

/**
 * 選考フィルター用選考フロー情報作成
 *
 * @param searchResult
 */
const createFilterDisplaySelenctionFlowInfo = (searchResult: SearchResult) => {
  const map = new Map()

  searchResult.flowMasterInfo.forEach(info => {
    const selectionFlowSettingId = info.selectionFlowSettingId
    const selectionName = info.selectionName
    map.set(selectionFlowSettingId, selectionName)
  })

  const filterDisplaySelenctionFlowInfoArray: FilterDisplaySelenctionFlowInfo[] = []
  map.forEach((value, key) => {
    const filterDisplaySelenctionFlowInfo: FilterDisplaySelenctionFlowInfo = {
      selectionFlowSettingId: key,
      selectionName: value,
    }
    filterDisplaySelenctionFlowInfoArray.push(filterDisplaySelenctionFlowInfo)
  })

  return filterDisplaySelenctionFlowInfoArray
}

/**
 * よく使うタグフィルター情報作成
 */
const createFilterDisplayCommonlyTagInfo = (searchResult: SearchResult) => {
  const tagMasterInfoArray = searchResult.tagMasterInfo

  tagMasterInfoArray.sort((a, b) => {
    //ソート：タグ件数（降順）
    if (a.tagCount < b.tagCount) {
      return 1
    }
    if (a.tagCount > b.tagCount) {
      return -1
    }
    return 0
  })

  const commonlyTagInfoArray: FilterTagInfo[] = []
  tagMasterInfoArray.forEach((info, index) => {
    if (index < 5) {
      const commonlyTagInfo: FilterTagInfo = {
        tagSettingId: info.tagSettingId, //タグ設定ID
        tagName: info.tagName, //タグ名称
      }
      commonlyTagInfoArray.push(commonlyTagInfo)
    } else {
      return
    }
  })

  return commonlyTagInfoArray
}

/**
 * フィルター処理
 *
 * @param filterCondition
 * @param searchResult
 * @todo 空チェックを共通メソッドから取得
 */
const filterEntryListDiplayInfo = (
  entryListDiplayInfo: Data[],
  filterCondition: FilterCondition,
  searchResult: SearchResult,
  specificConditionSelectionManagementIdList: string[]
) => {
  const nameForm = filterCondition.nameForm
  const jobCategoryForm = filterCondition.jobCategoryForm
  const selectionFlowSelect = filterCondition.selectionFlowSelect.value
  const progressStatusSelect = filterCondition.progressStatusSelect.value
  const progressStatusSelectProgressType =
    filterCondition.progressStatusSelectProgressType
  const judgmentStatusSelect = filterCondition.judgmentStatusSelect.value
  const tagSelect = filterCondition.tagSelect.map(tag => tag.value)
  const isUnread = filterCondition.isUnread

  const filterdEntryList = entryListDiplayInfo.filter(info => {
    let isTarget = true
    if (selectionFlowSelect !== '') {
      //選考フロー
      isTarget =
        info.selectionFlowSettingId === selectionFlowSelect
          ? isTarget && true
          : false
    }
    if (progressStatusSelect !== '') {
      if (progressStatusSelectProgressType !== '9') {
        //選考ステップ
        isTarget =
          info.progressStatusSettingId === progressStatusSelect &&
            !info.notApplicable
            ? isTarget && true
            : false
      } else {
        isTarget = info.notApplicable ? isTarget && true : false
      }
    }
    if (judgmentStatusSelect !== '') {
      //合否判定
      isTarget =
        info.decisionDivision === judgmentStatusSelect
          ? isTarget && true
          : false
    }
    if (nameForm !== '') {
      //氏名
      isTarget =
        info.nameForFilter.indexOf(nameForm) > -1 ? isTarget && true : false
    }
    if (jobCategoryForm !== '') {
      //応募職種
      isTarget =
        info.description.indexOf(jobCategoryForm) > -1
          ? isTarget && true
          : false
    }
    if (tagSelect.length > 0) {
      //タグ
      //選考フィルターで指定したタグの内、エントリーに関連づくタグ対象にないものが1つでもあれば表示対象から除外とする
      const infoTagList = info.tagList.map(i => i.tagSettingId)
      const unExistTagSettingId = tagSelect.find(i => !infoTagList.includes(i))

      isTarget = unExistTagSettingId === undefined ? isTarget && true : false
    }
    if (isUnread) {
      //履歴書未読のみ
      isTarget = info.unread ? isTarget && true : false
    }
    if (specificConditionSelectionManagementIdList.length > 0) {
      //特定条件
      isTarget = info.specificTargetFlag === '1' ? isTarget && true : false
    } else {
      isTarget =
        info.specificTargetDataGetFlag === '0' ? isTarget && true : false
    }
    return isTarget
  })

  return filterdEntryList
}

/**
 *
 * 表示順ソート処理
 *
 * @param diplayInfo
 * @param sortCondition
 * @return ソートしたdiplayInfo
 */
const sortDiplayInfo = (diplayInfo: Data[], sortCondition: SortCondition) => {
  //ソート用1次オブジェクトを作成
  const displayOrderInfoArray = diplayInfo.map(value => ({
    data: value,
    selectionManagementId: value.selectionManagementId, //選考管理ID
    jobSeekerIdForDisplay: value.id, //表示用求職者ID
    entryReceptionTime:
      value.datetime && value.datetime !== ''
        ? (stringToDateTime(value.datetime) as any).getTime()
        : null, //エントリー受付日時
    recommendTime: value.recommendTime
      ? (stringToDateTime(value.recommendTime) as any).getTime()
      : null, //推薦日時
    receivingTime: value.message.receivingTimeForSort,
    sendTime: value.message.sendTimeForSort,
  }))

  let order = ''
  if (sortCondition.order === '4') {
    order = sortCondition.order
  } else {
    order =
      sortCondition.order === LIST_DISPLAY_TYPE_SIMPLE_LIST
        ? '0'
        : sortCondition.order
  }
  //ソート処理
  switch (order) {
    case '0':
      //[0]応募日順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：エントリー受付日時（降順：nullは一番下）
        if (a.entryReceptionTime === null) {
          return 1
        }
        if (b.entryReceptionTime === null) {
          return -1
        }
        if (a.entryReceptionTime < b.entryReceptionTime) {
          return 1
        }
        if (a.entryReceptionTime > b.entryReceptionTime) {
          return -1
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    case '1':
      //[1]受信メッセージの新しい順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：受信日時（降順：nullは一番下）
        if (a.receivingTime !== null || b.receivingTime !== null) {
          if (a.receivingTime === null) {
            return 1
          }
          if (b.receivingTime === null) {
            return -1
          }
          if (a.receivingTime < b.receivingTime) {
            return 1
          }
          if (a.receivingTime > b.receivingTime) {
            return -1
          }
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：エントリー受付日時（降順：nullは一番下）
        if (a.entryReceptionTime !== null || b.entryReceptionTime !== null) {
          if (a.entryReceptionTime === null) {
            return 1
          }
          if (b.entryReceptionTime === null) {
            return -1
          }
          if (a.entryReceptionTime < b.entryReceptionTime) {
            return 1
          }
          if (a.entryReceptionTime > b.entryReceptionTime) {
            return -1
          }
        }
        //第4ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    case '2':
      //[2]送信メッセージの新しい順
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：送信日時（降順：nullは一番下）
        if (a.sendTime !== null || b.sendTime !== null) {
          if (a.sendTime === null) {
            return 1
          }
          if (b.sendTime === null) {
            return -1
          }
          if (a.sendTime < b.sendTime) {
            return 1
          }
          if (a.sendTime > b.sendTime) {
            return -1
          }
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：エントリー受付日時（降順：nullは一番下）
        if (a.entryReceptionTime !== null || b.entryReceptionTime !== null) {
          if (a.entryReceptionTime === null) {
            return 1
          }
          if (b.entryReceptionTime === null) {
            return -1
          }
          if (a.entryReceptionTime < b.entryReceptionTime) {
            return 1
          }
          if (a.entryReceptionTime > b.entryReceptionTime) {
            return -1
          }
        }
        //第4ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    case '4':
      //[4]推薦日時(降順)
      displayOrderInfoArray.sort((a, b) => {
        //第1ソート：推薦日時（降順：nullは一番下）
        if (a.recommendTime !== null || b.recommendTime !== null) {
          if (a.recommendTime === null) {
            return 1
          }
          if (b.recommendTime === null) {
            return -1
          }
          if (a.recommendTime < b.recommendTime) {
            return 1
          }
          if (a.recommendTime > b.recommendTime) {
            return -1
          }
        }
        //第2ソート：表示用求職者ID（昇順）
        if (a.jobSeekerIdForDisplay > b.jobSeekerIdForDisplay) {
          return 1
        }
        if (a.jobSeekerIdForDisplay < b.jobSeekerIdForDisplay) {
          return -1
        }
        //第3ソート：選考管理ID（降順）
        if (a.selectionManagementId < b.selectionManagementId) {
          return 1
        }
        if (a.selectionManagementId > b.selectionManagementId) {
          return -1
        }
        return 0
      })
      break
    default:
      break
  }

  return displayOrderInfoArray.map(i => i.data)
}

const createFilterDisplayProgressInfoArray = (
  selectSelectionFlowSettingId: string,
  listId: string,
  searchCondition: SearchCondition,
  flowMasterInfoArray: FlowMasterInfo[],
  applicantsListInfo: ApplicantsListInfo[]
) => {
  //検索条件の選考ステップで、内定者一覧or選考対象外一覧の選考ステップIDのリストを保持
  let additionalProgressStatusFilters: string[] = []
  if (listId === '0') {
    //選考中一覧
    const selectionStepObj = searchCondition.selectionStepObj
    if (selectionStepObj) {
      if (selectionStepObj.length === 0) {
        //検索条件で選考ステップを1つも指定していない場合
        flowMasterInfoArray.forEach(flowMasterInfo => {
          additionalProgressStatusFilters.push(
            flowMasterInfo.progressStatusSettingId
          )
        })
      } else {
        //検索条件で選考ステップを1つ以上指定している場合
        selectionStepObj.forEach(selectionStep => {
          const flowMasterInfo = flowMasterInfoArray.find(
            flow =>
              Number(flow.progressStatusSettingId) ===
              Number(selectionStep.selectionStepId)
          )
          if (flowMasterInfo) {
            if (
              ['2', '3', '4', '5'].includes(flowMasterInfo.progressType) ||
              ['6', '7', '8', '9'].includes(flowMasterInfo.progressType)
            ) {
              //検索条件詳細で選択した選考ステップ項目が内定者一覧 or 選考対象外一覧の選考ステップである場合
              additionalProgressStatusFilters.push(
                flowMasterInfo.progressStatusSettingId
              )
            }
          }
        })
      }
    }
  }

  const filterDisplayProgressInfoArray: FilterDisplayProgressInfo[] = []
  flowMasterInfoArray.forEach(flowMasterInfo => {
    //進捗種別
    const progressStatusSettingId = flowMasterInfo.progressStatusSettingId
    const progressName = flowMasterInfo.progressName
    const progressClassification = flowMasterInfo.progressType

    //件数カウント処理
    let count = 0
    if (progressClassification !== '9') {
      //対象外以外の場合
      count = applicantsListInfo.filter(
        info =>
          info.selectionFlowSettingId === selectSelectionFlowSettingId &&
          info.progressStatusSettingId === progressStatusSettingId &&
          info.nonTargetFlag !== '1'
      ).length
    } else {
      //対象外の場合
      count = applicantsListInfo.filter(
        info =>
          info.selectionFlowSettingId ===
          flowMasterInfo.selectionFlowSettingId && info.nonTargetFlag === '1'
      ).length
    }

    const filterDisplayProgressInfo: FilterDisplayProgressInfo = {
      progressStatusSettingId: progressStatusSettingId, //進捗ステータス設定ID
      progressName: progressName, //進捗名
      progressClassification: progressClassification, // 進捗区分
      count: count, //件数
    }

    switch (listId) {
      case '0':
        //選考中一覧
        if (additionalProgressStatusFilters.includes(progressStatusSettingId)) {
          filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
        } else {
          switch (progressClassification) {
            case '0':
            case '1':
              //[0]応募[1]カスタム
              filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
              break
            default:
              break
          }
        }
        break
      case '1':
        //内定者一覧
        switch (progressClassification) {
          case '2':
          case '3':
          case '4':
          case '5':
            //[2]内定[3]インターンシップ参加[4]内定承諾[5]入社
            filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
            break
          default:
            break
        }
        break
      case '2':
        //選考対象外一覧
        switch (progressClassification) {
          case '6':
          case '7':
          case '8':
          case '9':
            //[6]不合格[7]不採用[8]辞退[9]対象外
            filterDisplayProgressInfoArray.push(filterDisplayProgressInfo)
            break
          default:
            break
        }
        break
      default:
        break
    }
  })
  return filterDisplayProgressInfoArray
}

const createInPageData = (listDisplayType: string, newDiplayInfo: Data[]) => {
  if (listDisplayType == LIST_DISPLAY_TYPE_MAX_CARD) {
    const rowsPerPage = 100
    const page = 0
    const startIndex = page * rowsPerPage
    const endIndex =
      startIndex + rowsPerPage > newDiplayInfo.length
        ? newDiplayInfo.length
        : startIndex + rowsPerPage
    const InPageData: PagerMaxCard[] = newDiplayInfo
      .slice(startIndex, endIndex)
      .filter(i => i.specificTargetDataGetFlag === '0')
      .map(i => {
        return {
          selectionManagementId: i.selectionManagementId,
          sysVersionNumber1: i.sysVersionNumber1,
        }
      })
    return InPageData
  } else {
    return []
  }
}
