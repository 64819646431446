import request from 'utils/request'

import { MCAHS030UpdateRequest } from 'types/MCAHS030/MCAHS030UpdateRequest'
import { MCAHS030DeleteRequest } from 'types/MCAHS030/MCAHS030DeleteRequest'
import { MCAHS030CheckOptionRequest } from 'types/MCAHS030/MCAHS030CheckOptionRequest'

export const getUpdateInitialValuesApi = (recruitmentManagementFlagId: string) =>
  request({
    url: `/MCAHS030/init/${recruitmentManagementFlagId}`,
    method: 'post',
})
export const doUpdateApi = (param: MCAHS030UpdateRequest) =>
  request({
    url: '/MCAHS030/register',
    method: 'post',
    data: param,
})

export const doDeleteApi = (param: MCAHS030DeleteRequest) =>
  request({
    url: '/MCAHS030/delete',
    method: 'post',
    data: param,
})

export const doCheckOptionApi = (param: MCAHS030CheckOptionRequest) =>
  request({
    url: '/MCAHS030/checkOption',
    method: 'post',
    data: param,
  })