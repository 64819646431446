import {
  getCountRequest,
  getInitListRequest,
  mCAHS010BulkOperationRequest,
  mCAHS010OrderRequest,
  // #72427 次期開発2022年12月 start
  managementCopyApi,
  // #72427 次期開発2022年12月 end
} from 'apis/MCAHS010Api'
import { doCreateApi, initCheckApi } from 'apis/MCAHS020Api'
import {
  doDeleteApi,
  doUpdateApi,
  doCheckOptionApi,
  getUpdateInitialValuesApi,
} from 'apis/MCAHS030Api'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  doCreate,
  doDelete,
  doCheckOption,
  doUpdate,
  fetchRecruitmentManagementFlagList,
  getCount,
  getUpdateInitValues,
  setRecruitmentManagementFlag,
  setUpdateValues,
  updateRecruitmentManagementFlag,
  updateRecruitmentManagementFlagDisplayOrder,
  setUpdateDialogOpen,
  setCreateDialogOpen,
  optionListInfo,
  // #72427 次期開発2022年12月 start
  setCount,
  managementCopyReducer,
  // #72427 次期開発2022年12月 end
} from 'reducers/recruitmentManagementFlagReducer'
import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { MCAHS030UpdateRequest } from 'types/MCAHS030/MCAHS030UpdateRequest'
import { MCAHS020CreateRequest } from 'types/MCAHS020/MCAHS020CreateRequest'
import { MCAHS010OrderRequest } from 'types/MCAHS010/MCAHS010OrderRequest'
import { MCAHS030CheckOptionRequest } from 'types/MCAHS030/MCAHS030CheckOptionRequest'
import { getMessage } from 'common/messageUtil'
import { magiContants } from 'utils/contants'
import { getRecruitmentManagementDivision } from 'selectors/authSelectors'
// #72427 次期開発2022年12月 start
import { MCAHS020InitRequest } from 'types/MCAHS020/MCAHS020InitRequest'
import { getGlobalTargetYear } from '../selectors/authSelectors'
// #72427 次期開発2022年12月 end
function* getInitListSaga() {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInitListRequest, recruitmentManagementDivision)
    yield put(setRecruitmentManagementFlag(data))
  } catch (error) {
  }
}

// #72427 次期開発2022年12月 start
function* getCountSaga(action: ReturnType<typeof getCount>) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    yield call(getCountRequest, recruitmentManagementDivision)
    const data = yield call(initCheckApi,action.payload)
    let optionNameList: optionListInfo[] = []
    const newData = {
      recruitmentManagementFlag: '',
      recruitmentManagementFlagId: '',
      targetYear: data.targetYear,
      targetYearList: data.targetYearList,
      display: 0,
      type: 0,
      optionName: optionNameList,
      optionNameId: data.optionNameId,
      recruitmentManagementDivision: data.recruitmentManagementDivision,
    }
    yield put(setCount(newData))
// #72427 次期開発2022年12月 end
    yield put(setCreateDialogOpen(true))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateInvisebleFlag(
  action: ReturnType<typeof updateRecruitmentManagementFlag>
) {
  try {
    yield call(mCAHS010BulkOperationRequest, action.payload)
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInitListRequest, recruitmentManagementDivision)
    yield put(setRecruitmentManagementFlag(data))
    if (action.payload.showHide === magiContants.DISABLE) {
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCAHS010_005)))
    } else if (action.payload.showHide === magiContants.ENABLE) {
      yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCAHS010_006)))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* updateDisplayOrder(
  action: ReturnType<typeof updateRecruitmentManagementFlagDisplayOrder>
) {
  try {
    let updateOrders: MCAHS010OrderRequest = {
      recruitmentManagementFlagId: [],
      sysVersionNumber: [],
    }
    action.payload.forEach(item => {
      updateOrders.recruitmentManagementFlagId.push(
        item.recruitmentManagementFlagSettingId
      )
      updateOrders.sysVersionNumber.push(item.sysVersionNumber)
    })
    yield call(mCAHS010OrderRequest, updateOrders)
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data = yield call(getInitListRequest, recruitmentManagementDivision)
    yield put(setRecruitmentManagementFlag(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// -----------------MCAHS020_管理項目登録_START----------------------//
function* doCreateSaga(action: ReturnType<typeof doCreate>) {
  try {
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
// #72427 次期開発2022年12月 start
    const tmpArr = action.payload.optionName.map((i: any) => {
      return {
        optionName: i.optionName.split('_is_init_')[0],
        optionSettingId: i.optionSettingId,
        displayOrder: i.displayOrder,
      }
    })
    const data: MCAHS020CreateRequest = {
      ...action.payload,
      recruitmentManagementDivision: recruitmentManagementDivision,
      optionName: tmpArr,
    }
    yield call(doCreateApi, data)
    yield put(setCreateDialogOpen(false))
    const dataInit = yield call(
      getInitListRequest,
      recruitmentManagementDivision
    )
    yield put(setRecruitmentManagementFlag(dataInit))
    // 年度対応 start
    const globalTargetYear: ReturnType<
    typeof getGlobalTargetYear
  > = yield select(getGlobalTargetYear)
    if(action.payload.targetYear == globalTargetYear){
      yield put(openSnackbar(magiContants.MESSAGECODE_MCAHS020_011))
    }else{
      yield put(openSnackbar(magiContants.MESSAGECODE_MCAHS020_015))
    }
// #72427 次期開発2022年12月 end
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAHS020_管理項目登録_END------------------------//
// -----------------MCAHS030_管理項目編集_START----------------------//
function* getUpdateInitValuesSaga(
  action: ReturnType<typeof getUpdateInitValues>
) {
  try {
    const data = yield call(getUpdateInitialValuesApi, action.payload)
    let optionNameList: optionListInfo[] = []
    for (let i = 0; i < data.option.length; i++) {
      let optionInfo : optionListInfo = {
        optionName: data.option[i] + '_is_init_',
        optionSettingId: data.optionSettingIds[i],
      }
      optionNameList.push(optionInfo)
    }
    const newData = {
      recruitmentManagementFlag: data.recruitmentManagementFlagName,
      display: Number.parseInt(data.invisibleFlag),
      type: Number.parseInt(data.type),
      optionNameId: data.optionId,
      optionName: optionNameList,
      recruitmentManagementFlagId: action.payload,
      sysVersionNumber: data.sysVersionNumber,
	  // #72427 次期開発2022年12月 start
      targetYear: data.yearDeterminingMediaFlag
	  // #72427 次期開発2022年12月 end
    }
    if (data.option[0] === null) {
      const newData2 = {
        ...newData,
        optionName: [],
      }
      yield put(setUpdateDialogOpen(true))
      yield put(setUpdateValues(newData2))
    } else {
      yield put(setUpdateDialogOpen(true))
      yield put(setUpdateValues(newData))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* doUpdateSaga(action: ReturnType<typeof doUpdate>) {
  try {
    const tmpArr = action.payload.optionName.map((i: any) => {
      return {
        optionName: i.optionName.split('_is_init_')[0],
        optionSettingId: i.optionSettingId,
        displayOrder: i.displayOrder,
      }
    })
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const data: MCAHS030UpdateRequest = {
      ...action.payload,
      type: action.payload.type + '',
      optionName: tmpArr,
      optionNameId: [],
      versionNumber: action.payload.sysVersionNumber,
      recruitmentManagementDivision: recruitmentManagementDivision,
      // #72427 次期開発2022年12月 start	
      // 対象年度
      targetyear: action.payload.targetYear
      // #72427 次期開発2022年12月 end
    }

    yield call(doUpdateApi, data)
    yield put(setUpdateDialogOpen(false))
    const dataInit = yield call(
      getInitListRequest,
      recruitmentManagementDivision
    )
    yield put(setRecruitmentManagementFlag(dataInit))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAHS030_016))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
function* doDeleteSaga(action: ReturnType<typeof doDelete>) {
  try {
    yield call(doDeleteApi, action.payload)
    yield put(setUpdateDialogOpen(false))
    const recruitmentManagementDivision: ReturnType<
      typeof getRecruitmentManagementDivision
    > = yield select(getRecruitmentManagementDivision)
    const dataInit = yield call(
      getInitListRequest,
      recruitmentManagementDivision
    )
    yield put(setRecruitmentManagementFlag(dataInit))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAHS030_017))
  } catch (error) {
    if (error.message === magiContants.MESSAGECODE_RESULT_NULL) {
      yield put(setRecruitmentManagementFlag([]))
      return
    }
    yield put(openModal(error.message))
  }
}

function* doCheckOptionSaga(action: ReturnType<typeof doCheckOption>) {
  try {
    yield call(doCheckOptionApi, action.payload.MCAHS030CheckOptionRequest)
    action.payload.removeOption(action.payload.optionNameArray, action.payload.name, action.payload.index)
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// -----------------MCAHS030_管理項目編集_END------------------------//
// #72427 次期開発2022年12月 start
function* managementCopySaga(action: ReturnType<typeof managementCopyReducer>) {
  try {
    yield call(managementCopyApi,action.payload)
    const data = yield call(initCheckApi,action.payload)
    let optionNameList: optionListInfo[] = []
    for (let i = 0; i < data.option.length; i++) {
      let optionInfo : optionListInfo = {
        optionName: data.option[i] + '_is_init_',
        optionSettingId: data.optionSettingIds[i],
      }
      optionNameList.push(optionInfo)
    }
    const copyData = {
      recruitmentManagementFlag: data.recruitmentManagementFlagName,
      display: magiContants.NUMBER_0,
      type: Number.parseInt(data.type),
      optionNameId: data.optionId,
      optionName: optionNameList,
      recruitmentManagementFlagId: action.payload.recruitmentManagementFlagId,
      targetYear: data.yearDeterminingMediaFlag,
      targetYearList: data.targetYearList,
      recruitmentManagementDivision: data.recruitmentManagementDivision,
    }
    if (data.option[0] === null) {
      const copyData2 = {
        ...copyData,
        optionName: [],
      }
      yield put(setCreateDialogOpen(true))
      yield put(setCount(copyData2))
    } else {
      yield put(setCreateDialogOpen(true))
      yield put(setCount(copyData))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// #72427 次期開発2022年12月 end
export default function* recruitmentManagementFlagSaga() {
  yield all([
    takeEvery(fetchRecruitmentManagementFlagList, getInitListSaga),
    takeEvery(getCount, getCountSaga),
    takeLeading(updateRecruitmentManagementFlag, updateInvisebleFlag),
    takeEvery(updateRecruitmentManagementFlagDisplayOrder, updateDisplayOrder),
    // -----------------MCAHS020_管理項目登録_START----------------------//
    takeLeading(doCreate, doCreateSaga),
    // -----------------MCAHS020_管理項目登録_END------------------------//

    // -----------------MCAHS030_管理項目編集_START----------------------//
    takeEvery(getUpdateInitValues, getUpdateInitValuesSaga),
    takeLeading(doUpdate, doUpdateSaga),
    takeLeading(doDelete, doDeleteSaga),
    takeLeading(doCheckOption, doCheckOptionSaga),
    // -----------------MCAHS030_管理項目編集_END------------------------//
    // #72427 次期開発2022年12月 start
    takeEvery(managementCopyReducer, managementCopySaga),
	// #72427 次期開発2022年12月 end
  ])
}
