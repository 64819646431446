import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCAHS010BulkOperationRequest } from 'types/MCAHS010/MCAHS010BulkOperationRequest'
import { actionChannel } from 'redux-saga/effects'
import { initialValues as createInitialValues } from '../pages/MCAHS020/formConfig'
import { initialValues as copyFormValues } from '../pages/MCAHS020/formConfig'
import { initRequest } from '../pages/MCAHS020/formConfig'
import { InitialValues } from '../pages/MCAHS020/formConfig'
import { initDeleteEmploymentManagementDataRequest } from '../pages/MCAHS030/formConfig'
import { initialValues as updateFormValues } from '../pages/MCAHS030/formConfig'
import { MCAHS020CreateRequest } from 'types/MCAHS020/MCAHS020CreateRequest'
import { MCAHS020InitRequest } from 'types/MCAHS020/MCAHS020InitRequest'
import { MCAHS010CopyRequest } from 'types/MCAHS010/MCAHS010CopyRequest'
import { MCAHS030DeleteRequest } from 'types/MCAHS030/MCAHS030DeleteRequest'
import { MCAHS030CheckOptionRequest } from 'types/MCAHS030/MCAHS030CheckOptionRequest'
import { List } from 'immutable'
import { number } from '@storybook/addon-knobs'

export interface Permission {
  [key: string]: string | null
  id: string
  name: string
  sortOrder: string | null
  status: string | null
}

// #72427 次期開発2022年12月 start
export interface RecruitmentManagementFlag {
  [key: string]: string | any
  recruitmentManagementFlagSettingId: string //採用管理フラグ設定ID
  recruitmentManagementFlagName: string //採用管理フラグ名
  type: string //種別
  invisibleFlag: string //非表示フラグ
  updatedDate: Date //更新日時
  updaterId: string //更新者ID
  sysVersionNumber: number //sysバージョン番号
  permissions: Permission[] //権限
  targetYearList:string[] // 表示可能年度一覧
  yearDeterminingMediaFlag:string
// #72427 次期開発2022年12月 end
}

export interface UpdateFormValues {
  recruitmentManagementFlagId: string;
  // #72427 次期開発2022年12月 start
  targetYear: string;
  // #72427 次期開発2022年12月 end
  recruitmentManagementFlag: string;
  display: number;
  type: number;
  optionName: optionListInfo[];
  sysVersionNumber: string;
}

// #72427 次期開発2022年12月 start
export interface copyFormValues {
  recruitmentManagementFlagId: string;
  targetYear: string;
  targetYearList: string[];
  recruitmentManagementFlag: string;
  display: number;
  type: number;
  optionName: optionListInfo[];
}
// #72427 次期開発2022年12月 end
export interface optionListInfo {
  optionName: string;
  optionSettingId: string;
}

interface InitialValuesType {
  recruitmentManagementFlagList: RecruitmentManagementFlag[]
  createFormValues: MCAHS020CreateRequest
  // #72427 次期開発2022年12月 start
  initFormValues: MCAHS020InitRequest
  updateFormValues: UpdateFormValues
  copyFormValues: InitialValues
  // #72427 次期開発2022年12月 end
  dialogOpen: boolean
  createDialogOpen: boolean
  updateDialogOpen: boolean
  dialogMessage: string
  DeleteEmploymentManagementDataRequest: MCAHS030DeleteRequest
}

const initialState: InitialValuesType = {
  recruitmentManagementFlagList: [],
  createFormValues: createInitialValues,
  // #72427 次期開発2022年12月 start
  initFormValues: initRequest,
  updateFormValues: updateFormValues,
  copyFormValues: copyFormValues,
  // #72427 次期開発2022年12月 end
  dialogOpen: false,
  createDialogOpen: false,
  updateDialogOpen: false,
  dialogMessage: '',
  DeleteEmploymentManagementDataRequest: initDeleteEmploymentManagementDataRequest,
}

const recruitmentManagementFlagSlice = createSlice({
  name: 'recruitmentManagementFlag',
  initialState,
  reducers: {
    fetchRecruitmentManagementFlagList(state) {
      return state
    },
    setRecruitmentManagementFlag(
      state,
      action: PayloadAction<RecruitmentManagementFlag[]>
    ) {
      state.recruitmentManagementFlagList = action.payload
      return state
    },
    // #72427 次期開発2022年12月 start
    getCount(state,action: PayloadAction<MCAHS020InitRequest>) {
      state.initFormValues=action.payload
      return state
    },

    setCount(state, action: PayloadAction<InitialValues>) {
      state.copyFormValues = action.payload
      return state
    },
	// #72427 次期開発2022年12月 end

    createRecruitmentManagementFlag(state) {
      return state
    },
    updateRecruitmentManagementFlag(
      state,
      action: PayloadAction<MCAHS010BulkOperationRequest>
    ) {
      return state
    },
    updateRecruitmentManagementFlagDisplayOrder(
      state,
      action: PayloadAction<RecruitmentManagementFlag[]>
    ) {
      state.recruitmentManagementFlagList = action.payload
      return state
    },
    deleteRecruitmentManagementFlag(state, action: PayloadAction<string>) {
      return state
    },
    setCreateDialogOpen(
      state,
      action: PayloadAction<boolean>
    ) {
      state.createDialogOpen = action.payload
      return state
    },
    // -----------------MCAHS020_管理項目登録_START----------------------//
    // initCheck(state) {
    //   return state
    // },
    getCreateValues(state) {
      return state
    },
    setCreateValues(state, action: PayloadAction<MCAHS020CreateRequest>) {
      state.createFormValues = action.payload
      return state
    },
    doCreate(state, action: PayloadAction<MCAHS020CreateRequest>) {
      return state
    },
    // -----------------MCAHS020_管理項目登録_END------------------------//

    // -----------------MCAHS030_管理項目編集_START----------------------//
    getUpdateInitValues(state, action: PayloadAction<string>) {
      return state
    },
    setUpdateValues(state, action: PayloadAction<UpdateFormValues>) {
      state.updateFormValues = action.payload
      return state
    },
    doUpdate(state, action: PayloadAction<UpdateFormValues>) {
      return state
    },
    doDelete(state, action: PayloadAction<MCAHS030DeleteRequest>) {
      return state
    },
    doCheckOption(state, action: PayloadAction<{
      removeOption: (
        optionNameArray: string[],
        name: string,
        index: number
      ) => void
      optionNameArray: string[]
      name: string
      index: number
      MCAHS030CheckOptionRequest: MCAHS030CheckOptionRequest
    }>
    ) {
      return state
    },
    // -----------------MCAHS030_管理項目編集_END------------------------//
    setDialogOpen(
      state,
      action: PayloadAction<{ open: boolean; message: string }>
    ) {
      state.dialogOpen = action.payload.open
      state.dialogMessage = action.payload.message
      return state
    },
    setUpdateDialogOpen(
      state,
      action: PayloadAction<boolean>
    ) {
      state.updateDialogOpen = action.payload
      return state
    },
	// #72427 次期開発2022年12月 start
    managementCopyReducer(state, action:PayloadAction<MCAHS010CopyRequest>){
      state.initFormValues.recruitmentManagementFlagId=action.payload.recruitmentManagementFlagId
    return state
    }
	// #72427 次期開発2022年12月 end
  },
})

export const {
  fetchRecruitmentManagementFlagList,
  setRecruitmentManagementFlag,
  getCount,
  createRecruitmentManagementFlag,
  updateRecruitmentManagementFlag,
  updateRecruitmentManagementFlagDisplayOrder,
  deleteRecruitmentManagementFlag,
  setCreateDialogOpen,
  // -----------------MCAHS020_管理項目登録_START----------------------//
  // initCheck,
  getCreateValues,
  setCreateValues,
  doCreate,
  // -----------------MCAHS020_管理項目登録_END------------------------//

  // -----------------MCAHS030_管理項目編集_START----------------------//
  getUpdateInitValues,
  setUpdateValues,
  doUpdate,
  doDelete,
  doCheckOption,
  // -----------------MCAHS030_管理項目編集_END------------------------//
  setDialogOpen,
  setUpdateDialogOpen,
  // #72427 次期開発2022年12月 start
  setCount,
  managementCopyReducer,
  // #72427 次期開発2022年12月 end
} = recruitmentManagementFlagSlice.actions
export default recruitmentManagementFlagSlice.reducer
