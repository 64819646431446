import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialDecisionProcessChangeQueryResult } from 'pages/MCAYS020/formConfig'
import {
  initialdecisionProgressChangeQueryResultList,
  initResultDto,
} from 'pages/MCAYS030/formConfig'
import { MCAYS020NotAdoptedNotificationPreferenceCheckRequest } from 'types/MCAYS020/MCAYS020NotAdoptedNotificationPreferenceCheckRequest'
import { MCAYS030NotAdoptedNotificationPreferenceCheckRequest } from 'types/MCAYS030/MCAYS030NotAdoptedNotificationPreferenceCheckRequest'

export interface initCondition {
  selectionManagementId: number //選考管理ID
  recruitmentManagementDivision: string //採用管理区分
}

export interface SelectionFlowList {
  [key: number]: number
  progressStatusSettingId: number //選考ステップ設定ID
  progressType: string //進捗種別
  displayOrder: number //表示順
  progressName: string //進捗名
  selectionName: string //選考名
  decisionDivisionType: string //判定区分種別
}

export interface NoAdoptionQueryResult {
  nascp: string //不採用ステータス変更設定
  asrlsrp: string //書類選考不採用通知送信予約設定
  irlsrp: string //面接不採用通知送信予約設定
}

export interface ManagerFlowStartCommentQueryResult {
  selectionManagementId: number //選考管理ID
  progressStatusSettingId: number //選考ステップ設定ID
  nonTargetFlag: string //対象外フラグ
  initialSelectionManagementId: number //初期選考管理ID
  decisionDivision: string //判定区分
  starEvaluation: string //星評価
  comment: string //コメント
  updateTime: string //更新日時(コメント)
  updater: string //更新者(コメント)
  smSysVersionNumber: number //sysバージョン番号（選考管理）
  psmSysVersionNumber: number //sysバージョン番号（選考ステップ管理）
  seSysVersionNumber: number //sysバージョン番号（星評価）
  // 転職March #75621 START
  failureReasonId: string //不合格理由ID;
  declineReasonId: string //辞退理由ID;
  interviewTime: string //面接日時;
  inputContent: string //入力内容;
  manipulationTime: string //操作日時;
  // 転職March #75621 END
}

export interface SysVersionNumberList {
  [key: number]: number
  selectionManagementId: number //選考管理ID
  progressStatusSettingId: number //選考ステップ設定ID
  sysVersionNumberPsma: number //sysバージョン番号（選考ステップ管理）
}

export interface Status {
  [key: number]: number
  id: number //key
  name: string //進捗名
  settingId: number //選考ステップ設定ID
}

export interface SelectionFlowInitData {
  flag: number // 旗の位置
  leftSelect: number // 左進捗の選択位置
  rightSelect: number // 右進捗の選択位置
  leftGrey: boolean // 左進捗のグレー表示の標識
  rightGrey: boolean // 右進捗のグレー表示の標識
  target: boolean // 対象の標識
  leftProgressList: Status[] // 左進捗の選考ステップ
  rightProgressList: Status[] // 右進捗の選考ステップ
}

export interface Priority {
  selectionManagementIdArray: number[] //選考管理ID
  starEvaluation: string //星評価
  sysVersionNumberSeva: number //sysバージョン番号（星評価）
}

export interface UpdateJudge {
  selectionManagementId: number //選考管理ID
  sysVersionNumberSman: number //sysバージョン番号（選考管理）
  sysVersionNumberPsma: number //sysバージョン番号（選考ステップ管理）
  passingStatusDivisionId: string //合否区分ID
  historyInsertDivision: string //履歴登録区分
  progressName: string //進捗名
  selectionName: string //選考名
  progressStatusSettingId: number //選考ステップ設定ID
}

export interface UpdateJudgeNextStep {
  selectionManagementId: number //選考管理ID
  sysVersionNumberSman: number //sysバージョン番号（選考管理）
  sysVersionNumberPsma: number //sysバージョン番号（選考ステップ管理）
  passingStatusDivisionId: string //合否区分ID
  historyInsertDivision: string //履歴登録区分
  progressName: string //進捗名
  selectionName: string //選考名
  selectMagSelectFlowInfoOutDtoList: SelectionFlowList[] //選考フロー情報
  selectMagSelectSelectionInfoOutDto: ManagerFlowStartCommentQueryResult //選考情報
  progressStatusSettingId: number //選考ステップ設定ID(直前)
  historyInsertDivisionId: string //履歴登録区分
  progressTypeBefore: string //進捗種別(変更元)
  displayOrderBefore: number //表示順(変更元)
  decisionDivisionTypeBefore: string //判定区分種別(変更元)
  progressStatusSettingIdBefore: number //選考ステップ設定ID(変更元)
  progressStatusSettingIdAfter: number //選考ステップ設定ID(変更先)
  progressTypeAfter: string //進捗種別(変更先)
  displayOrderAfter: number //表示順(変更先)
  progressNameAfter: string //進捗名(変更先)
  decisionDivisionTypeAfter: string //判定区分種別(変更先)
  sysVersionNumberList: SysVersionNumberList[] //未判定バージョン番号情報
  selectionFlowInitData: SelectionFlowInitData //選考フロー初期化情報
  decisionDivisionType: string //判定区分種別(初期表示)
  inValidCheckCount: string[] //メッセージ送信エラー情報
  failureReasonIdCodeList: CodeList[] //不合格理由IDコード
  declineReasonIdCodeList: CodeList[] //辞退理由IDコード
}

export interface UpdateJudgeRejectionNotice {
  selectionManagementId: number //選考管理ID
  sysVersionNumberSman: number //sysバージョン番号（選考管理）
  sysVersionNumberPsma: number //sysバージョン番号（選考ステップ管理）
  passingStatusDivisionId: string //合否区分ID
  historyInsertDivision: string //履歴登録区分
  progressName: string //進捗名
  selectionName: string //選考名
  progressStatusSettingId: number //選考ステップ設定ID
  entryId: number
  jobSeekerId: number
  notAdoptedNotificationType: string
  selectionManagementSysVersionNumber: number
  progressManagementSysVersionNumber: number
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>
}

export interface Versiondata {
  smSysVersionNumber: number //sysバージョン番号（選考管理）
  psmSysVersionNumber: number //sysバージョン番号（選考ステップ管理）
}

export interface UpdateJudgeResult {
  sysVersionNumberList: SysVersionNumberList[] //未判定バージョン番号情報
  sysVersionNumberSman: number //sysバージョン番号（選考管理）
  sysVersionNumberPsma: number //sysバージョン番号（選考ステップ管理）
  decisionDivision: string //判定区分
  message: string //更新完了メッセージ
}

export interface DecisionProcessChangeQueryResult {
  selectMagSelectFlowInfoOutDtoList: SelectionFlowList[] //選考フロー情報
  selectMagSelectSelectionInfoOutDto: ManagerFlowStartCommentQueryResult //選考情報
  progressStatusSettingId: number //選考ステップ設定ID(直前)
  passingStatusDivisionId: string //合否区分ID
  historyInsertDivisionId: string //履歴登録区分
  progressTypeBefore: string //進捗種別(変更元)
  displayOrderBefore: number //表示順(変更元)
  decisionDivisionTypeBefore: string //判定区分種別(変更元)
  progressStatusSettingIdBefore: number //選考ステップ設定ID(変更元)
  progressStatusSettingIdAfter: number //選考ステップ設定ID(変更先)
  progressTypeAfter: string //進捗種別(変更先)
  displayOrderAfter: number //表示順(変更先)
  progressNameAfter: string //進捗名(変更先)
  decisionDivisionTypeAfter: string //判定区分種別(変更先)
  sysVersionNumberList: SysVersionNumberList[] //未判定バージョン番号情報
  selectionFlowInitData: SelectionFlowInitData //選考フロー初期化情報
  decisionDivisionType: string //判定区分種別(初期表示)
  inValidCheckCount: string[] //メッセージ送信エラー情報
  selectionName: string //選考名
  // 転職March #75621 START
  failureReasonIdCodeList: CodeList[] //不合格理由IDコード
  declineReasonIdCodeList: CodeList[] //辞退理由IDコード
  // 転職March #75621 END
}

// 転職March #75621 START
export interface CodeList {
  itemCode: string //コード値
  itemName: string //名称
}
// 転職March #75621 END

export interface UnifiedInit {
  selectionManagementIdArray: number[] //選考管理ID
  recruitmentManagementDivision: string //採用管理区分
}
export interface UnifiedPriority {
  selectionManagementIdArray: number[] //選考管理ID
  starEvaluation: string //星評価
  selectMagSelectSelectionInfoOutDto: ManagerFlowStartCommentQueryResult[] //選考情報
}

export interface DecisionProcessChangeQueryResultList {
  selectionManagementIdArray: number[] //選考管理ID
  selectMagSelectFlowInfoOutDtoList: SelectionFlowList[]
  selectMagSelectSelectionInfoOutDto: ManagerFlowStartCommentQueryResult[]
  sysVersionNumberList: SysVersionNumberList[] //バージョン番号と判定区分情報
  progressStatusSettingIdBefore: number //選考ステップ設定ID（変更元）
  passingStatusDivisionId: string //合否区分ID
  historyInsertDivisionId: string //履歴登録区分
  progressTypeBefore: string //進捗種別(変更元)
  displayOrderBefore: number //表示順(変更元)
  progressNameBefore: string //進捗名(変更元)
  decisionDivisionTypeBefore: string //判定区分種別(変更元)
  progressStatusSettingIdAfter: number //選考ステップ設定ID(変更先)
  progressTypeAfter: string //進捗種別(変更先)
  displayOrderAfter: number //表示順(変更先)
  progressNameAfter: string //進捗名(変更先)
  selectionName: string //選考名
  decisionDivisionTypeAfter: string //判定区分種別(変更先)
  selectionFlowInitData: SelectionFlowInitData //選考フロー初期化情報
  decisionDivisionType: string //判定区分種別(初期表示)
  inValidCheckCount: string[] //メッセージ送信エラー情報
  initResultDto: initResultDto //処理上限情報
}

export interface DecisionProcessChangeQueryNextStepResultList {
  selectionManagementIdArray: number[] //選考管理ID
  selectMagSelectFlowInfoOutDtoList: SelectionFlowList[]
  selectMagSelectSelectionInfoOutDto: ManagerFlowStartCommentQueryResult[]
  sysVersionNumberList: SysVersionNumberList[] //バージョン番号と判定区分情報
  passingStatusDivisionId: string //合否区分ID
  selectionName: string //選考名
  historyInsertDivisionId: string //履歴登録区分
  progressStatusSettingIdBefore: number //選考ステップ設定ID（変更元）
  progressTypeBefore: string //進捗種別(変更元)
  displayOrderBefore: number //表示順(変更元)
  progressNameBefore: string //進捗名(変更元)
  decisionDivisionTypeBefore: string //判定区分種別(変更元)
  progressStatusSettingIdAfter: number //選考ステップ設定ID(変更先)
  progressTypeAfter: string //進捗種別(変更先)
  displayOrderAfter: number //表示順(変更先)
  progressNameAfter: string //進捗名(変更先)
  decisionDivisionTypeAfter: string //判定区分種別(変更先)
  selectionFlowInitData: SelectionFlowInitData //選考フロー初期化情報
  decisionDivisionType: string //判定区分種別(初期表示)
  inValidCheckCount: string[] //メッセージ送信エラー情報
  initResultDto: initResultDto //処理上限情報
}

interface SelectionInfo {
  managementId: number[]
  jobSeekerId: number[]
  entryId: number[]
  screenRepresentationID: number[]
}

interface ProgressListList {
  decisionProcessChangeQueryResult: DecisionProcessChangeQueryResult
  decisionProcessChangeQueryResultList: DecisionProcessChangeQueryResultList
  recruitmentManagementDivision: string //採用管理区分
  commentOpenFlag: boolean //コメント入力開くフラグ(MCAYS020)
  pageDialogOpen020: boolean //画面openフラグ(MCAYS020)
  pageDialogOpen030: boolean //画面openフラグ(MCAYS030)
  updateFlag: boolean //画面更新フラグ
  openManageListDialog: boolean //採用管理変更画面openフラグ(MCAXS340)
  errorMessageFlag: boolean // messageSendチェック画面openフラグ
  decisionDivisionList: string[]
  decisionDivisionEqualsFlag: boolean
  mCAXS340Open: boolean
  // 次期9月対応 #58576 start
  // MCAYS020
  openDecideAlertMessageFlag: boolean // 不合格通知確認チェックメッセージopenフラグ
  openSelectionStepAlertMessageFlag: boolean // 不採用通知設定チェックメッセージopenフラグ
  decideUpdateCommitFlag: boolean // 合否判定更新commitフラグ
  processChangeCommitFlag: boolean // 進捗更新commitフラグ
  openDecideFlag: boolean // 合否判定更新時、確認メッセージopenフラグ(Booste)
  openFlag: boolean // 進捗変更時、確認メッセージopenフラグ
  openTwoFlag: boolean // 進捗変更時、確認メッセージopenフラグ
  // MCAYS030
  openDecideAlertMessageFlag030: boolean // 不合格通知確認チェックメッセージopenフラグ(030)
  openSelectionStepAlertMessageFlag030: boolean // 不採用通知設定チェックメッセージopenフラグ(030)
  decideUpdateCommitFlag030: boolean // 合否判定更新commitフラグ(030)
  processChangeCommitFlag030: boolean // 進捗更新commitフラグ(030)
  openDecideFlag030: boolean // 合否判定更新時、確認メッセージopenフラグ(030)
  openFlag030: boolean // 進捗変更時、確認メッセージopenフラグ(030)
  openTwoFlag030: boolean // 進捗変更時、確認メッセージopenフラグ(030)
  openSpecialSelStepAlertMessageFlag030: boolean // 不採用通知設定特殊選考チェックメッセージopenフラグ(030)
  // 次期9月対応 #58576 end
}

const progressList: ProgressListList = {
  decisionProcessChangeQueryResult: initialDecisionProcessChangeQueryResult,
  decisionProcessChangeQueryResultList: initialdecisionProgressChangeQueryResultList,
  recruitmentManagementDivision: '',
  commentOpenFlag: false,
  pageDialogOpen020: false, //画面openフラグ(MCAYS020)
  pageDialogOpen030: false, //画面openフラグ(MCAYS030)
  updateFlag: false, //画面更新フラグ
  openManageListDialog: false, //採用管理変更画面openフラグ(MCAXS340)
  errorMessageFlag: false, // messageSendチェック画面openフラグ
  decisionDivisionList: [],
  decisionDivisionEqualsFlag: true,
  mCAXS340Open: false,
  // 次期9月対応 #58576 start
  // MCAYS020
  openDecideAlertMessageFlag: false, // 不合格通知確認チェックメッセージopenフラグ
  openSelectionStepAlertMessageFlag: false, // 不採用通知設定チェックメッセージopenフラグ
  decideUpdateCommitFlag: false, // 合否判定更新commitフラグ
  processChangeCommitFlag: false, // 進捗更新commitフラグ
  openDecideFlag: false, // 合否判定更新時、確認メッセージopenフラグ(Booste)
  openFlag: false, // 進捗変更時、確認メッセージopenフラグ
  openTwoFlag: false, // 進捗変更時、確認メッセージopenフラグ
  // MCAYS030
  openDecideAlertMessageFlag030: false, // 不合格通知確認チェックメッセージopenフラグ(030)
  openSelectionStepAlertMessageFlag030: false, // 不採用通知設定チェックメッセージopenフラグ(030)
  decideUpdateCommitFlag030: false, // 合否判定更新commitフラグ(030)
  processChangeCommitFlag030: false, // 進捗更新commitフラグ(030)
  openDecideFlag030: false, // 合否判定更新時、確認メッセージopenフラグ(030)
  openFlag030: false, // 進捗変更時、確認メッセージopenフラグ(030)
  openTwoFlag030: false, // 進捗変更時、確認メッセージopenフラグ(030)
  openSpecialSelStepAlertMessageFlag030: false, // 不採用通知設定特殊選考チェックメッセージopenフラグ(030)
  // 次期9月対応 #58576 end
}

const processChangeSlice = createSlice({
  name: 'selectionStatusUpdate',
  initialState: progressList,
  reducers: {
    processChangeInit(state, action: PayloadAction<initCondition>) {
      return state
    },
    processChangeMessageInit(state, action: PayloadAction<initCondition>) {
      return state
    },
    setProcessChangeInit(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResult>
    ) {
      state.decisionProcessChangeQueryResult = action.payload
      if (
        null != action.payload.selectMagSelectSelectionInfoOutDto &&
        '1' == action.payload.selectMagSelectSelectionInfoOutDto.nonTargetFlag
      ) {
        let progressStatusSettingId =
          action.payload.selectMagSelectSelectionInfoOutDto
            .progressStatusSettingId
        let flowList = action.payload.selectMagSelectFlowInfoOutDtoList
        for (let i in flowList) {
          if (progressStatusSettingId == flowList[i].progressStatusSettingId) {
            state.decisionProcessChangeQueryResult.decisionDivisionType = ''
          }
        }
      }
      return state
    },
    setCommentOpenFlag(state, action: PayloadAction<boolean>) {
      state.commentOpenFlag = action.payload
      return state
    },
    setPageDialogOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen020 = action.payload
      return state
    },
    updateDecide(state, action: PayloadAction<UpdateJudge>) {
      return state
    },
    updateDecideNextStep(state, action: PayloadAction<UpdateJudgeNextStep>) {
      return state
    },
    updateDecideRejectionNotice(
      state,
      action: PayloadAction<UpdateJudgeRejectionNotice>
    ) {
      return state
    },
    updateDecideRejectionNoticeSP(
      state,
      action: PayloadAction<{
        date: UpdateJudgeRejectionNotice
        onSendMessage: (messageInputRequest: any) => void
      }>
    ) {
      return state
    },
    setUpdateDecide(state, action: PayloadAction<UpdateJudgeResult>) {
      state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.psmSysVersionNumber =
        action.payload.sysVersionNumberPsma
      state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.decisionDivision =
        action.payload.decisionDivision
      if (
        '5' == action.payload.decisionDivision ||
        '6' == action.payload.decisionDivision
      ) {
        state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.smSysVersionNumber =
          action.payload.sysVersionNumberSman
      }
      state.decisionProcessChangeQueryResult.sysVersionNumberList =
        action.payload.sysVersionNumberList
      return state
    },
    sendMessageChk(
      state,
      action: PayloadAction<{
        info: SelectionInfo | undefined
        onSendMessage: () => void
      }>
    ) {
      return state
    },
    setSendMessageChk(state, action: PayloadAction<string[]>) {
      state.decisionProcessChangeQueryResult.inValidCheckCount = action.payload
      return state
    },
    sendMessageChkTemp(state, action: PayloadAction) {
      return state
    },
    setSendMessageChkTemp(state, action: PayloadAction<string[]>) {
      state.decisionProcessChangeQueryResult.inValidCheckCount = []
      return state
    },
    processChangeUpd(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResult>
    ) {
      return state
    },
    setProcessChangeUpd(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResult>
    ) {
      state.decisionProcessChangeQueryResult = action.payload
      return state
    },
    priorityUpd(state, action: PayloadAction<Priority>) {
      return state
    },
    setpriorityUpd(state, action: PayloadAction<Priority>) {
      state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.seSysVersionNumber =
        action.payload.sysVersionNumberSeva
      state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.starEvaluation =
        action.payload.starEvaluation
      return state
    },
    setUpdateFlag(state, action: PayloadAction<boolean>) {
      state.updateFlag = action.payload
      return state
    },
    //S030 初期
    processChangeListInit(state, action: PayloadAction<UnifiedInit>) {
      return state
    },
    processChangeMessageListInit(state, action: PayloadAction<UnifiedInit>) {
      return state
    },
    setProcessChangeListInit(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      state.decisionProcessChangeQueryResultList = action.payload
      if (
        null != action.payload.selectMagSelectSelectionInfoOutDto[0] &&
        '1' ==
        action.payload.selectMagSelectSelectionInfoOutDto[0].nonTargetFlag
      ) {
        state.decisionProcessChangeQueryResultList.decisionDivisionType = ''
        state.decisionProcessChangeQueryResultList.selectionFlowInitData.flag = -1
        state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftSelect = -1
        state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightSelect = -1
      }
      return state
    },
    setPageDialogListOpen(state, action: PayloadAction<boolean>) {
      state.pageDialogOpen030 = action.payload
      return state
    },
    updateDecideList(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      return state
    },
    updateDecideListNextStep(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      return state
    },
    setUpdateDecideList(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      state.decisionProcessChangeQueryResultList = action.payload
      return state
    },
    progressChangeUpdate(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      return state
    },
    progressChangeUpdateMessage(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      return state
    },
    setProgressChangeUpdate(
      state,
      action: PayloadAction<DecisionProcessChangeQueryResultList>
    ) {
      state.decisionProcessChangeQueryResultList = action.payload
      return state
    },
    sendMessage(
      state,
      action: PayloadAction<{
        info: SelectionInfo | undefined
        onSendMessage: () => void
      }>
    ) {
      return state
    },
    setSendMessage(state, action: PayloadAction<string[]>) {
      state.decisionProcessChangeQueryResultList.inValidCheckCount =
        action.payload
      return state
    },
    sendMessageTemp(state, action: PayloadAction) {
      return state
    },
    setSendMessageTemp(state, action: PayloadAction<string[]>) {
      state.decisionProcessChangeQueryResultList.inValidCheckCount = []
      return state
    },
    evaluationUpd(state, action: PayloadAction<UnifiedPriority>) {
      return state
    },
    setEvaluationUpd(state, action: PayloadAction<UnifiedPriority>) {
      state.decisionProcessChangeQueryResultList.selectMagSelectSelectionInfoOutDto =
        action.payload.selectMagSelectSelectionInfoOutDto
      return state
    },
    leftEmpty(state, action: PayloadAction<number>) {
      return state
    },
    setLeftEmpty(state, action: PayloadAction<number>) {
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftSelect = -1
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.flag =
        action.payload
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightSelect =
        action.payload
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftGrey = true
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightGrey = false
      return state
    },
    toLeft(state, action: PayloadAction<SelectionFlowInitData>) {
      return state
    },
    setToLeft(state, action: PayloadAction<SelectionFlowInitData>) {
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftSelect =
        action.payload.leftSelect
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.flag =
        action.payload.flag
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightSelect =
        action.payload.rightSelect
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftGrey =
        action.payload.leftGrey
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightGrey =
        action.payload.rightGrey
      return state
    },
    leftReset(state, action: PayloadAction<SelectionFlowInitData>) {
      return state
    },
    setLeftReset(state, action: PayloadAction<SelectionFlowInitData>) {
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftSelect =
        action.payload.leftSelect
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.flag =
        action.payload.flag
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightSelect =
        action.payload.rightSelect
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.leftGrey =
        action.payload.leftGrey
      state.decisionProcessChangeQueryResultList.selectionFlowInitData.rightGrey =
        action.payload.rightGrey
      return state
    },
    leftResetStatus(state, action: PayloadAction<SelectionFlowInitData>) {
      return state
    },
    setLeftResetStatus(state, action: PayloadAction<SelectionFlowInitData>) {
      state.decisionProcessChangeQueryResult.selectionFlowInitData.leftSelect =
        action.payload.leftSelect
      state.decisionProcessChangeQueryResult.selectionFlowInitData.flag =
        action.payload.flag
      state.decisionProcessChangeQueryResult.selectionFlowInitData.rightSelect =
        action.payload.rightSelect
      state.decisionProcessChangeQueryResult.selectionFlowInitData.leftGrey =
        action.payload.leftGrey
      state.decisionProcessChangeQueryResult.selectionFlowInitData.rightGrey =
        action.payload.rightGrey
      return state
    },
    leftTemp(state, action: PayloadAction<number>) {
      return state
    },
    setLeftTemp(state, action: PayloadAction<number>) {
      state.decisionProcessChangeQueryResult.selectionFlowInitData.flag =
        action.payload
      return state
    },
    findReMaDivision(state, action: PayloadAction) {
      return state
    },
    setFindReMaDivision(state, action: PayloadAction<string>) {
      state.recruitmentManagementDivision = action.payload
      return state
    },
    decisionDivisionBlack(
      state,
      action: PayloadAction<{
        decisionDivision: string
        decisionDivisionList: string[]
      }>
    ) {
      return state
    },
    setDecisionDivisionBlack(
      state,
      action: PayloadAction<{
        decisionDivision: string
        decisionDivisionList: string[]
      }>
    ) {
      if (
        action.payload.decisionDivisionList.length == 0 ||
        action.payload.decisionDivisionList.length !=
        state.decisionProcessChangeQueryResultList
          .selectMagSelectSelectionInfoOutDto.length
      ) {
        for (
          let i = 0;
          i <
          state.decisionProcessChangeQueryResultList
            .selectMagSelectSelectionInfoOutDto.length;
          i++
        ) {
          state.decisionProcessChangeQueryResultList.selectMagSelectSelectionInfoOutDto[
            i
          ].decisionDivision = action.payload.decisionDivision
        }
      } else {
        for (
          let i = 0;
          i <
          state.decisionProcessChangeQueryResultList
            .selectMagSelectSelectionInfoOutDto.length;
          i++
        ) {
          state.decisionProcessChangeQueryResultList.selectMagSelectSelectionInfoOutDto[
            i
          ].decisionDivision = action.payload.decisionDivisionList[i]
        }
      }
      return state
    },
    setDecisionDivisionBack(state, action: PayloadAction<string>) {
      state.decisionProcessChangeQueryResult.selectMagSelectSelectionInfoOutDto.decisionDivision =
        action.payload
      return state
    },
    setOpenManageListDialog(state, action: PayloadAction<boolean>) {
      state.openManageListDialog = action.payload
      return state
    },
    setErrorMessageFlag(state, action: PayloadAction<boolean>) {
      state.errorMessageFlag = action.payload
      return state
    },
    setDecisionDivisionListAndFlag(
      state,
      action: PayloadAction<{
        decisionDivisionList: string[]
        decisionDivisionEqualsFlag: boolean
      }>
    ) {
      state.decisionDivisionList = action.payload.decisionDivisionList
      state.decisionDivisionEqualsFlag =
        action.payload.decisionDivisionEqualsFlag
      return state
    },
    setMCAXS340Open(state, action: PayloadAction<boolean>) {
      state.mCAXS340Open = action.payload
      return state
    },
    // 次期9月対応 #58576 start
    // MCAYS020
    notPassNotificationCheck020(state, action: PayloadAction<string>) {
      return state
    },
    notAdoptedNotificationPreferenceCheck020(
      state,
      action: PayloadAction<
        MCAYS020NotAdoptedNotificationPreferenceCheckRequest
      >
    ) {
      return state
    },
    setOpenDecideAlertMessageFlag(state, action: PayloadAction<boolean>) {
      state.openDecideAlertMessageFlag = action.payload
      return state
    },
    setOpenSelectionStepAlertMessageFlag(
      state,
      action: PayloadAction<boolean>
    ) {
      state.openSelectionStepAlertMessageFlag = action.payload
      return state
    },
    setDecideUpdateCommitFlag(state, action: PayloadAction<boolean>) {
      state.decideUpdateCommitFlag = action.payload
      return state
    },
    setProcessChangeCommitFlag(state, action: PayloadAction<boolean>) {
      state.processChangeCommitFlag = action.payload
      return state
    },
    setOpenDecideFlag(state, action: PayloadAction<boolean>) {
      state.openDecideFlag = action.payload
      return state
    },
    setOpenFlag(state, action: PayloadAction<boolean>) {
      state.openFlag = action.payload
      return state
    },
    setOpenTwoFlag(state, action: PayloadAction<boolean>) {
      state.openTwoFlag = action.payload
      return state
    },
    // MCAYS030
    notPassNotificationCheck030(state, action: PayloadAction<string>) {
      return state
    },
    notAdoptedNotificationPreferenceCheck030(
      state,
      action: PayloadAction<
        MCAYS030NotAdoptedNotificationPreferenceCheckRequest
      >
    ) {
      return state
    },
    setOpenDecideAlertMessageFlag030(state, action: PayloadAction<boolean>) {
      state.openDecideAlertMessageFlag030 = action.payload
      return state
    },
    setOpenSelectionStepAlertMessageFlag030(
      state,
      action: PayloadAction<boolean>
    ) {
      state.openSelectionStepAlertMessageFlag030 = action.payload
      return state
    },
    setDecideUpdateCommitFlag030(state, action: PayloadAction<boolean>) {
      state.decideUpdateCommitFlag030 = action.payload
      return state
    },
    setProcessChangeCommitFlag030(state, action: PayloadAction<boolean>) {
      state.processChangeCommitFlag030 = action.payload
      return state
    },
    setOpenDecideFlag030(state, action: PayloadAction<boolean>) {
      state.openDecideFlag030 = action.payload
      return state
    },
    setOpenFlag030(state, action: PayloadAction<boolean>) {
      state.openFlag030 = action.payload
      return state
    },
    setOpenTwoFlag030(state, action: PayloadAction<boolean>) {
      state.openTwoFlag030 = action.payload
      return state
    },
    setOpenSpecialSelStepAlertMessageFlag030(
      state,
      action: PayloadAction<boolean>
    ) {
      state.openSpecialSelStepAlertMessageFlag030 = action.payload
      return state
    },
    // 次期9月対応 #58576 end
  },
})

export const {
  processChangeInit,
  processChangeMessageInit,
  setProcessChangeInit,
  setCommentOpenFlag,
  setPageDialogOpen,
  setPageDialogListOpen,
  updateDecide,
  updateDecideNextStep,
  updateDecideRejectionNotice,
  updateDecideRejectionNoticeSP,
  setUpdateDecide,
  sendMessageChk,
  setSendMessageChk,
  sendMessageChkTemp,
  setSendMessageChkTemp,
  processChangeUpd,
  setProcessChangeUpd,
  priorityUpd,
  setpriorityUpd,
  setUpdateFlag,
  processChangeListInit,
  processChangeMessageListInit,
  setProcessChangeListInit,
  updateDecideList,
  updateDecideListNextStep,
  setUpdateDecideList,
  progressChangeUpdate,
  progressChangeUpdateMessage,
  setProgressChangeUpdate,
  sendMessage,
  setSendMessage,
  sendMessageTemp,
  setSendMessageTemp,
  evaluationUpd,
  setEvaluationUpd,
  leftEmpty,
  setLeftEmpty,
  toLeft,
  setToLeft,
  leftReset,
  setLeftReset,
  leftResetStatus,
  setLeftResetStatus,
  leftTemp,
  setLeftTemp,
  findReMaDivision,
  setFindReMaDivision,
  decisionDivisionBlack,
  setDecisionDivisionBlack,
  setOpenManageListDialog,
  setErrorMessageFlag,
  setDecisionDivisionListAndFlag,
  setMCAXS340Open,
  setDecisionDivisionBack,
  // 次期9月対応 #58576 start
  // MCAYS020
  notPassNotificationCheck020,
  notAdoptedNotificationPreferenceCheck020,
  setOpenDecideAlertMessageFlag,
  setOpenSelectionStepAlertMessageFlag,
  setDecideUpdateCommitFlag,
  setProcessChangeCommitFlag,
  setOpenDecideFlag,
  setOpenFlag,
  setOpenTwoFlag,
  // MCAYS030
  notPassNotificationCheck030,
  notAdoptedNotificationPreferenceCheck030,
  setOpenDecideAlertMessageFlag030,
  setOpenSelectionStepAlertMessageFlag030,
  setDecideUpdateCommitFlag030,
  setProcessChangeCommitFlag030,
  setOpenDecideFlag030,
  setOpenFlag030,
  setOpenTwoFlag030,
  setOpenSpecialSelStepAlertMessageFlag030,
  // 次期9月対応 #58576 end
} = processChangeSlice.actions
export default processChangeSlice.reducer
