/**
 * MCAZS010MessageQueryRequest validation
 */

import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({

  // 検索文字
  searchWordText: yup
    .string()
    .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) => miscRegexSet.halfWidth.test(value)),

  // 受信期間（FROM）
  receiveFromDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  // 受信期間（TO）
  receiveToDate: yup
    .string()
    .test('isDate', validationMessageSet.date, (value: any) => miscRegexSet.date.test(value)),

  statusType: yup.object().shape({
    // 状態_未読
    unreadCheck: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 状態_未返信
    unsentCheck: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 状態_返信済み
    sentCheck: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  }),
  
  attachmentType: yup.object().shape({
    // 添付_あり
    attachmentExist: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // 添付_なし
    attachmentNot: yup
      .string()
      .test('isFlag',validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  }),

  messageType: yup.object().shape({
    // メッセージ種別_通常
    messageTypeStandard: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // メッセージ種別_お問い合わせ
    messageTypeInquiry: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // メッセージ種別_匿名
    messageTypeAnonymous: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),

    // メッセージ種別_面接日程調整
    messageTypeInterviewAdjustment: yup
      .string()
      .test('isFlag', validationMessageSet.flag, (value: any) => miscRegexSet.flag.test(value)),
  }),

  // ページ数
  countPage: yup
    .number()
    .min(1, validationMessageSet.minValue)
});