import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Form } from 'formik'
import { RootState } from 'reducers'
import {
  setProgressAlertDialogOpen,
  getInitPopup
} from 'reducers/progressStagentAlertReducer'
import Link from '@material-ui/core/Link';
import { routeList } from 'routes/routes'
import { scheduledInitValues } from 'pages/MCBAS020/formConfig'
import { receiveSubmitValue } from 'pages/MCAZS010/formConfig'
import { magiContants } from 'utils/contants'
import { now, dateToString, stringToDate } from 'common/generalUtil'
import { openModal } from 'reducers/messageReducer'
import history from 'utils/history'
import { initFlag } from 'reducers/globalMenuReducer'
import { setPageChangeFlag } from 'reducers/messageListReducer'
import { setAlerted } from 'reducers/companyLoginReducer'
const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: '#1a1a1a',
    border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    marginLeft: '-73%',
    borderColor: '#cbcbcb',
    marginBottom:'-3%',
    '&:hover': {
      backgroundColor: '#c1d1eb',
      borderColor: '#999999',
    },
    minWidth: 150,
  },
  dialogTitle: {
    backgroundColor: '#133e80',
    color: 'white',
    width: '100%',
    height: '35px',
    fontSize: '15px',
    paddingLeft: '2%',
    lineHeight: '35px',
  },
  title: {
    color: (theme.palette as any).white,
    fontWeight: 700,
    fontSize: '15px',
  },
  comment: {
    fontSize: 14,
    padding: theme.spacing(0, 10, 0, 10),
    overflow: 'auto',
  },
  linkStyle: {
    color: '#4c4cFF',
    textDecoration: 'underline',
    lineHeight: '10px',
    width: '95%',
    marginLeft: '-15%',
  },
  pStyle:{
    color: '#4c4cFF',
    textDecoration: 'underline',
  },
}))

const ElasticAlert = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pageChangeFlag = useSelector((state: RootState) => state.messageList.pageChangeFlag)
  const globalMenu = useSelector((state: RootState) => state.globalMenu)
  // 前回ログイン日時
  const previousLoginTime = useSelector((state: RootState) => globalMenu.previousLoginTime)
  // 最終ログアウト日時
  const lastLogoutTime = useSelector((state: RootState) => globalMenu.lastLogoutTime)

  const alertDialog = useSelector(
    (state: RootState) => state.progressStagentAlert.open
  )
  const messageReceiveInfo = useSelector(
    (state: RootState) => state.progressStagentAlert.actionPopupInfo
  )
  const popupFlg = useSelector(
    (state: RootState) => state.progressStagentAlert.topBarFlg
  )
  const handleClose = () => {
    dispatch(setProgressAlertDialogOpen(false))
    dispatch(initFlag(false))
    dispatch(setAlerted("yes"))
  }

  // メッセージ管理（受信トレイ）へ
  const linkMessageInBox = (e: any) => {
    e.preventDefault()
    const dateLastLogout = stringToDate(lastLogoutTime)
    const datePreviousLogin = stringToDate(previousLoginTime)
    datePreviousLogin && datePreviousLogin.setHours(datePreviousLogin.getHours() + 2)
    const strlastLogout = dateToString(dateLastLogout)
    const strpreviousLogin = dateToString(datePreviousLogin)
    const messageInBox = {
      pathname: routeList.messageInBox,
      state: {
        request: {
          ...receiveSubmitValue,
          // [phase2] start
          receiveFromDate: lastLogoutTime === null || previousLoginTime === null ? dateToString(now()) : lastLogoutTime >= previousLoginTime ? strlastLogout : strpreviousLogin,
          // [phase2] end
          messageTypeInquiry: "1",
          messageTypeAnonymous: "1",
          removeFunctionId: magiContants.SCREEN_ID_MCAWS010,
        },
      },
    }
    if(pageChangeFlag === 0){
      dispatch(setPageChangeFlag(1))
    }else{
      dispatch(setPageChangeFlag(0))
    }
    history.push(messageInBox)
    handleClose()
  }

  // 面接調整一覧画面（キャンセル）へ
  const linkCancelScheduledList = (e: any) => {
    e.preventDefault()
    const scheduledList = {
      pathname: routeList.scheduledList,
      state: {
        request: {
          ...scheduledInitValues,
          isCancelContact: magiContants.PARAM_CHECKED_MCAWS010,
          interviewDateFrom: dateToString(now()),
        },
      },
    }
    history.push(scheduledList)
    handleClose()
  }

  // 面接調整一覧画面（本日実施予定）へ
  const linkScheduledList = (e: any) => {
    e.preventDefault()
    const displayDate = now()
    if (!displayDate) {
      dispatch(openModal(magiContants.MESSAGECODE_MCAWS010_001))
    } else {
      const scheduledList = {
        pathname: routeList.scheduledList,
        state: {
          request: {
            ...scheduledInitValues,
            isFixed: magiContants.PARAM_CHECKED_MCAWS010,
            interviewDateFrom: dateToString(displayDate),
            interviewDateTo: dateToString(displayDate),
          },
        },
      }
      history.push(scheduledList)
      handleClose()
    }
  }
  
  //初期処理
   useEffect(() => {
     if(!popupFlg){
      dispatch(getInitPopup({
        recruitmentManagementDivision: globalMenu.recruitmentManagementDivision, //採用管理区分
        previousLoginTime:  previousLoginTime, // 前回ログイン日時
        lastLogoutTime:  lastLogoutTime, // 最終ログアウト日時
      }))
     }
   }, [dispatch])

  return (
    /* 転職March #75621 START */
    globalMenu.marchServiceFlag === '1' && globalMenu.serviceMode ==='1' ? <></> :
    // 転職March #75621 END
    <Dialog maxWidth='sm' fullWidth  open={alertDialog}>
      <div className={classes.dialogTitle}>
        <label>ご確認をお願いします</label>
      </div>
      <DialogContent>
        <Form className={classes.form}>
          <div className="box-info" >
            <p className="alert" style={{ paddingLeft: '2%', lineHeight: '35px' }} > 
            <span> 以下の内容について、ご確認をお願いします。</span> 
            </p>
            {messageReceiveInfo.interviewCancelMessage !== "" &&
              (<p className={classes.pStyle}>
                <Link href="#" 
                  className={classes.linkStyle}
                  onClick={linkCancelScheduledList}>
                    {messageReceiveInfo.interviewCancelMessage}
                </Link>
              </p>)
              } 
              {messageReceiveInfo.contactUnansweredMessage !== "" &&
              (<p className={classes.pStyle} >
                  <Link href="#"
                    className={classes.linkStyle}
                    onClick={linkMessageInBox}>
                      {messageReceiveInfo.contactUnansweredMessage}
                  </Link>
                </p> )}
               {messageReceiveInfo.interviewCurrentMessage !== "" &&
              (<p className={classes.pStyle} >
                    <Link href="#"
                      className={classes.linkStyle}
                      onClick={linkScheduledList}>
                        {messageReceiveInfo.interviewCurrentMessage}
                    </Link>
                 </p>)
                }
         </div>
          <DialogActions className={classes.actions}>
            <Button 
              onClick={handleClose}
              variant='contained'
              className={classes.cancelButton}>
              閉じる
          </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ElasticAlert
